import { useBDBoundData, useLocalBoundData } from "../../../hooks/useBoundData"
import { useContext, useEffect, useState, useRef } from "react"
import { WrappedInLoader } from "../../dumbs"
import { _nopropagate } from "../../../helpers/utils"
import { dataContext } from "../../../contexts/datacontext"
import { apiContext } from "../../../contexts/apicontext"
import { Button } from "../../button"
import { ScrollSpy } from "../../scrollspy"
import { GET_CALLS } from "../../../consts"
function SingleCampaign({ campaign, onClick }) {
  return (
    <div
      onClickCapture={onClick}
      className="campaign-list-item nc100-fg txt-left padding-left-4x padding-right-2x padding-y-2x caption-regular-font cursor-pointer"
    >
      {campaign.name}{" "}
      {campaign?.total_posts ? (
        <span className="nc50-fg txt-left">
          ({campaign?.category === 2 ? campaign?.total_creators : campaign?.total_posts})
        </span>
      ) : null}
    </div>
  )
}
function BoardPopup({
  kind,
  onDismiss,
  onBoardClick,
  onAddNewClick,
  className = "",
  hasDraft = false,
  watchNew = false,
  nextDialog = "",
  type = "normal",
  hideNew = false,
  showContentBoard = true,
}) {
  const { loader, payload, pagination } = useLocalBoundData({
    ...GET_CALLS.all_board_list,
    filter: { simple: true, board_type: 1, is_archived: false },
  })
  const {
    loader: tk_loader,
    payload: tk_payload,
    pagination: tk_pagination,
  } = useLocalBoundData({
    ...GET_CALLS.all_board_list,
    filter: { simple: true, board_type: 2, is_archived: false },
    skip: !(type === "tracking"),
  })
  const [finalList, setFinalList] = useState({})
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const listener = () => {
    console.log("listener")
    onDismiss && onDismiss()
  }

  useEffect(() => {
    document.body.addEventListener("click", listener, { capture: false, once: true })
    if (document.querySelector(".closes-other-popups"))
      document.querySelector(".closes-other-popups").addEventListener("click", listener, { capture: false, once: true })
  }, [])
  const boardClicked = (board) => {
    if (onBoardClick) onBoardClick(board)
    onDismiss && onDismiss()
  }
  const addNew = (e) => {
    _nopropagate(e)
    api?.alert?.createOrEditBoard({ hasDraft, nextDialog, kind })
    if (onAddNewClick) onAddNewClick()
    onDismiss && onDismiss()
  }

  const content_payload = (payload ?? [])?.filter((c) => c.category === 1)
  const creator_payload = (payload ?? [])?.filter((c) => c.category === 2)
  return (
    <div
      onClick={_nopropagate}
      className={`campaign-popup padding-3x nc10-border border-radius-dynamic border-1 nc00-bg padding-2x margin-bottom ${className}`}
    >
      <WrappedInLoader
        loader={loader || tk_loader}
        data={payload}
        silent={data[`bd_sec_${"board_list_tracking"}_page`] > 1 || data[`bd_sec_${"board_list"}_page`] > 1}
      >
        <div onClick={_nopropagate} className="campaign-section-container">
          <div className="cp-section">
            <div className="caption-highlight nc30-fg margin-left-2x margin-bottom txt-left ">
              {type == "tracking" ? "Select tracking panel" : "Select board"}
            </div>
            {type == "tracking" ? (
              <>
                {type == "tracking" && tk_payload?.length ? (
                  <p className="margin-left-2x caption-highlight txt-left  primary-fg ">Tracking panels</p>
                ) : null}
                {(tk_payload ?? []).map((_board) => (
                  <SingleCampaign
                    onClick={(e) => {
                      _nopropagate(e)
                      boardClicked(_board)
                    }}
                    key={_board.id}
                    campaign={_board}
                  />
                ))}

                {tk_payload && tk_pagination?.hasNext && (
                  <ScrollSpy
                    rebindTrigger={data[`bd_sec_${"board_list_tracking"}_raw`]?.pagination?.next}
                    loader={loader}
                    onEngage={tk_pagination.loadNext}
                  />
                )}
              </>
            ) : null}
            {type == "tracking" && payload?.length ? (
              <p className="margin-left-2x caption-highlight txt-left primary-fg margin-x-2x margin-bottom">Boards</p>
            ) : null}

            {showContentBoard && (
              <>
                {content_payload?.length ? (
                  <p className="margin-left-4x caption-regular txt-left nc30-fg margin-x-2x">Content</p>
                ) : null}
                {(content_payload ?? []).map((_board) => (
                  <SingleCampaign
                    onClick={(e) => {
                      _nopropagate(e)
                      boardClicked(_board)
                    }}
                    key={_board.id}
                    campaign={_board}
                  />
                ))}
              </>
            )}

            {creator_payload?.length ? (
              <p className="margin-left-4x caption-regular txt-left nc30-fg margin-x-2x">Creator</p>
            ) : null}
            {(creator_payload ?? []).map((_board) => (
              <SingleCampaign
                onClick={(e) => {
                  _nopropagate(e)
                  boardClicked(_board)
                }}
                key={_board.id}
                campaign={_board}
              />
            ))}

            {payload && pagination?.hasNext && (
              <ScrollSpy
                rebindTrigger={data[`bd_sec_${"board_list"}_raw`]?.pagination?.next}
                loader={loader}
                onEngage={pagination?.loadNext}
              />
            )}
          </div>
        </div>
      </WrappedInLoader>

      {hideNew ? null : (
        <Button
          fluid
          onClickCapture={addNew}
          type="ghost"
          className="margin-top-2x button campaign-compact-list-action  txt-left"
        >
          Create a new {type == "tracking" ? "tracking panel" : "board"}
        </Button>
      )}
    </div>
  )
}

function BothTypeBoardPopup({
  kind,
  onDismiss,
  onBoardClick,
  onAddNewClick,
  className = "",
  hasDraft = false,
  watchNew = false,
  nextDialog = "",
}) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleMouseEnter = () => {
    setIsOpen(true)
  }

  const handleMouseLeave = () => {
    setIsOpen(false)
  }
  return (
    <div ref={dropdownRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button>Add to</button>
      {isOpen && (
        <div style={{ position: "absolute" }}>
          <p>tracking</p>
          <p>tracking</p>
          <p>tracking</p>
        </div>
      )}
    </div>
  )
}

export { BoardPopup, BothTypeBoardPopup }
