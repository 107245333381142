import React, { useContext, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { dataContext } from "../../../contexts/datacontext"
import { useFilteredContent } from "../../../hooks/useFilteredContents"
import {
  AICommentDumbUI,
  ArrowLessTooltip,
  Card,
  DotSeparator,
  ExpandButtonInline,
  ExpandableText,
  PreviousComment,
  PreviousCommentWithState,
  WrappedInLoader,
} from "../../dumbs"
import { apiContext } from "../../../contexts/apicontext"
import { EcoSystemThumbnail, SimpleCreatorInfo, TinyEcoSystemThumbnail } from "../../contentThumbnail"
import {
  COMMENT_STATE_MESSAGES,
  DISCOVERY_POST_SECTION_CONFIG,
  MASS_ENGAGE_SORT_KEY,
  SORT_BY_CONTENT,
  TIME_FRAME,
} from "../../../consts"
import { ContentFilter, DiscoveryPlatformFilter, SubBrandFilterTabs } from "../contentAndCreatorFilter"
import { ScrollSpy } from "../../scrollspy"
import { TitleSection } from "./beSections"
import dayjs from "dayjs"
import UTCTime from "dayjs/plugin/utc"
import { Button } from "../../button"
import { BrandTabLink, SubBrandNames } from "../../pages/brandEcosystem"
import { Outlet, useLocation, useSearchParams } from "react-router-dom"
import { useToast } from "../../../hooks/useToast"
import { useBDBoundData, useBDPostData, useLocalBoundData } from "../../../hooks/useBoundData"
import {
  useAIComment,
  useBrandConfig,
  useClickAway,
  useCommentRestrictionAndStats,
  useContentModificationHelper,
  useGeneratedComment,
  useLikeContent,
} from "../../../hooks/helperHooks"
import {
  BanSolid,
  ChatgptIcon,
  CheckMediumFilledMono,
  HeartLargeFilledMono,
  HeartLargeOutlinedMono,
  RegenerateSmall,
  SmileyFaceFilledMono,
  SmileyFaceMono,
} from "../../Icons"
import { _lsr, _lsw, _nopropagate } from "../../../helpers/utils"
import { FullLoader, InfinityLoader, Loader, ShimmerLoader } from "../../loaders"
import Masonry from "react-responsive-masonry"
import { useInView } from "react-intersection-observer"
import { GET_CALLS } from "../../../consts"
import { Select } from "../../select"
import { AutoHeightTextarea } from "../../input"
import { CandidatesNps, useModalHelper } from "../../dialogs/creatorContentModal"
import EmojiPicker from "emoji-picker-react"
import { useAtom } from "jotai"
import { clientSideContent, engageStatsAtom } from "../../../store/store"
import ReactTooltip from "react-tooltip"
import { ToggleWithTitle } from "../../toggle"

dayjs.extend(UTCTime)

const AIGenerationCommentContent = ({
  className = "margin-y-3x",
  content,
  platform,
  skip_previous_comment,
  creator,
  ai_generated_comment,
  showNoCommentButton = false,
  // posting_state,
}) => {
  const { comment, setComment, posting_state, postCurrentComment, postCommentLoader, posted_comment, onArchive } = useContentModificationHelper({ content: content })
  const { onGenerate, generateLoader } = useGeneratedComment({ content: content })
  const [data, dispatch] = useContext(dataContext)
  const [showEmoji, setShowEmoji] = useState(false)
  const api = useContext(apiContext)
  const { connect_youtube_enabled } = useBrandConfig()
  const acceptedPlatform = ["tiktok"]
  if (connect_youtube_enabled) acceptedPlatform.push("youtube")
  const isTikApiConnected = data?.me?.is_user_tikapi_connected || data?.me?.brand?.is_tikapi_connected

  return (
    <div className={className}>
      {posted_comment ? (<>
        <PreviousCommentWithState
          loading={postCommentLoader}
          comment={posted_comment}
          postingMassage={COMMENT_STATE_MESSAGES[posting_state]}
          failed={posting_state === "COMMENT_FAILED"}
        />
      </>) : null}
      {
        (!!posted_comment && !(posting_state === "COMMENT_FAILED")) || <>
          <AICommentDumbUI
            generateLoader={generateLoader}
            commentEnable={true}
            onChatGPTClick={() => api?.alert?.playWithGPT(undefined, { content, creator })}
            comment={comment}
            setComment={setComment}
            platform={content?.content_platform}
            onSend={postCurrentComment}
            onGenerate={onGenerate}
            showNoCommentButton={showNoCommentButton}
            approveEnable={isTikApiConnected}
            onArchiveContent={() => onArchive(true)}
            postingCommentLoader={postCommentLoader}
          />
        </>
      }

    </div>
  )
}

function contentByDay(_con = [], field_name = "scraped_timestamp", clientSideValue = {}) {
  const contents = {}

  for (let index = 0; index < _con.length; index++) {
    const _c = _con[index]
    _c.original_index = index
    let date = _c?.content?.[field_name]
    date = dayjs(date).utc().format("YYYY-MM-DD")

    if (clientSideValue?.[_c?.content?.id]?.is_archived_from_ai_comment) {
      continue
    }

    if (contents?.[date]) {
      contents[date].push(_c)
    } else {
      contents[date] = [_c]
    }
  }

  let dates = Object.keys(contents)
  dates.sort((a, b) => b - a)

  return { contents, dates }
}

const HeartButton = ({
  className = "",
  isLiked = false,
  content_id,
  content_platform,
  parent_comment = null,
  border = true,
  text = true,
}) => {
  const { clientSideLikeValue, onLikeContent, loader } = useLikeContent({
    content_id,
    content_platform,
    parent_comment,
  })
  const currentLikeState = clientSideLikeValue ?? isLiked
  const onLike = (e) => {
    _nopropagate(e)
    onLikeContent({ is_like: !currentLikeState })
  }
  return (
    <div
      onClick={onLike}
      className={`${border ? "border-1 nc05-border" : ""} border-radius-4 padding-y-2x ${text ? "padding-left-3x padding-right-2x" : ""}   flex nc75-fg cursor-pointer ${loader ? "action-disabled-faded" : ""}  ${className} a-center j-center as-center`}
    >
      {text ? <span className="margin-right button-tiny-font">{currentLikeState ? "Liked" : "Like"}</span> : null}
      <span className={`flex a-center j-center ${currentLikeState ? "" : ""}`}>
        {" "}
        {currentLikeState ? HeartLargeFilledMono : HeartLargeOutlinedMono}
      </span>
    </div>
  )
}

const ContentByDate = ({ date, content, showModal, showNoCommentButton, titleElement = null, restriction = false }) => {
  const [dataKey, setDataKey] = useState("ai_summary")
  const [data, dispatch] = useContext(dataContext)
  const isPoliticalEngage = data?.me?.brand?.settings?.is_political_engage_enabled

  if (!content) return <div></div>

  return (
    <div className="margin-y-2x ">
      <div className="flex j-space-between a-center margin-top-2x margin-bottom-4x">
        <h5 className="headline-small-highlight  ">{dayjs(date).format("DD MMMM YYYY")}</h5>
        {titleElement}
      </div>
      <div className="padding-y-2x margin-y-4x nc75-fg border-radius-8 border-1 nc10-border nc00-bg mass-engage-creator-list">
        <div></div>
        <div>
          <span className="caption-regular-font">Caption</span>
        </div>
        {isPoliticalEngage ? (
          <ToggleWithTitle
            firstTitle="AI Summary"
            lastTitle="Transcript"
            className=""
            value={dataKey == "ai_summary"}
            onClick={() => setDataKey(dataKey === "ai_summary" ? "transcript" : "ai_summary")}
          />
        ) : (
          <div>
            <span className="caption-regular-font">Transcript</span>
          </div>
        )}
        <div>
          <span className="caption-regular-font">AI comment</span>
        </div>

        {content.map?.((_item, _idx) => {
          const restrictionClass =
            restriction && !_item?.content?.posted_comment ? "pointer-event-none opacity-half" : ""
          return (
            <React.Fragment key={_idx}>
              <hr className="hr full-span-grid-column"></hr>
              <div className={`col-start-1 padding-left-3x ${restriction ? "opacity-half" : ""}`}>
                <TinyEcoSystemThumbnail
                  postImageElement={
                    isPoliticalEngage ? (
                      <CandidatesNps
                        className="border-top-1 nc10-border padding-2x center wrapped gap-2"
                        candidates={{
                          harris: _item?.content?.kamala_nps ?? null,
                          trump: _item?.content?.trump_nps ?? null,
                          rfk: _item?.content?.rfk_nps ?? null,
                          vance: _item?.content?.vance_nps ?? null,
                          walz: _item?.content?.tim_nps ?? null,
                          biden: _item?.content?.biden_nps ?? null,
                        }}
                      />
                    ) : (
                      <>
                        {/* <HeartButton
                        className={`full-width border-top-1 nc10-border ${restrictionClass}`}
                        content_id={_item?.content?.id}
                        isLiked={_item?.content?.is_liked_on_platform}
                        content_platform={_item?.content?.content_platform}
                        border={false}
                      /> */}
                      </>
                    )
                  }
                  hidePostedAt={false}
                  onClick={() => {
                    showModal(_item, content, _idx, null)
                  }}
                  key={_item.content.id}
                  payload={_item}
                />
              </div>
              <div className={`col-start-2 body-regular-font ${restrictionClass}`}>
                <ExpandableText
                  text={_item?.content?.text}
                  lines={3}
                  showMoreClasses={"txt-bold"}
                  showLessClasses={"txt-bold"}
                  showMoreText={"... see more"}
                />
              </div>
              {isPoliticalEngage && dataKey === "ai_summary" ? (
                <div className={`col-start-3 body-regular-font ${restrictionClass}`}>
                  <ExpandableText
                    text={_item?.content?.ai_issue_summary}
                    lines={3}
                    showMoreClasses={"txt-bold"}
                    showLessClasses={"txt-bold"}
                    showMoreText={"... see more"}
                  />
                </div>
              ) : (
                <div className={`col-start-3 body-regular-font ${restrictionClass}`}>
                  <ExpandableText
                    text={_item?.content?.transcription}
                    lines={3}
                    showMoreClasses={"txt-bold"}
                    showLessClasses={"txt-bold"}
                    showMoreText={"... see more"}
                  />
                </div>
              )}
              <div className={`col-start-4 padding-bottom-2x ${restrictionClass}`}>
                <AIGenerationCommentContent
                  showNoCommentButton={showNoCommentButton}
                  hide_after_post
                  has_comment_failed={_item?.content?.has_comment_failed}
                  posting_state={_item?.content?.posting_state}
                  skip_auto_fetch_ai_comment={true}
                  skip_previous_comment={true}
                  skip_auto_generate={true}
                  ai_generated_comment={_item?.content?.ai_generated_comment}
                  className="padding-right-3x"
                  content={_item?.content}
                  creator={_item?.creator}
                  platform={_item?.content?.content_platform}
                />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}

const sectionName = [
  {
    name: "All",
    value: "mass_engage_all",
  },
  {
    name: "Open",
    value: "mass_engage_open",
  },
  {
    name: "Pending",
    value: "mass_engage_pending",
  },
  {
    name: "Answered",
    value: "mass_engage_answered",
  },
  {
    name: "Failed",
    value: "mass_engage_failed",
  },
]

const SingleComment = ({ commentPayload, contentPayload }) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const { is_ai_comment_enabled, connect_youtube_enabled } = useBrandConfig()
  const [pullStart, setPullStart] = useState(false)
  const unique_comment_id = commentPayload?.id ? commentPayload?.id : ""
  const pullID = data?.ai_reply_pull_set?.[unique_comment_id]?.id
  const toast = useToast()
  const shouldPull = pullID && pullStart
  const {
    loader: ai_single_comment_loader,
    payload: ai_single_comment_payload,
    pollingStatus,
  } = useBDBoundData({
    section: shouldPull ? "get_single_ai_generated_reply" : "",
    polling: shouldPull && pullStart,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    uniqueSectionPostfix: pullID,
  })
  const {
    loader: ai_loader,
    payload: ai_comment_payload,
    executeCall: generateComment,
  } = useBDPostData({ section: "ai_generated_comment", uniqueSectionPostfix: unique_comment_id })
  const {
    loader,
    payload,
    executeCall: postComment,
  } = useBDPostData({ section: "add_comment_content", uniqueSectionPostfix: unique_comment_id })
  const [comment, setComment] = useState("")
  const { syncRestriction, setDailyStats } = useCommentRestrictionAndStats()
  const onRegenarate = async () => {
    console.log("generating reply to comment")
    setPullStart(false)
    api.common.updateManyObjectTypeInDataContent({
      id: unique_comment_id,
      dataContextVariableName: "ai_reply_pull_set",
      deleteObj: true,
    })
    if (!is_ai_comment_enabled) {
      toast("AI comment generation is disabled for this brand", BanSolid)
      return
    }
    const payload = {
      content_id: commentPayload.content,
      brand_id: data?.me?.brand?.id,
      parent_comment_id: unique_comment_id,
    }
    console.log(payload)
    const res = await generateComment(payload)
    if (res) {
      setPullStart(true)
      api.common.updateManyObjectTypeInDataContent({
        id: unique_comment_id,
        dataContextVariableName: "ai_reply_pull_set",
        newValue: res,
      })
    }
  }
  const dataContextVariableName = "ai_reply_comment_obj"

  let clientLastCommit = data?.[dataContextVariableName]?.[unique_comment_id]?.last_comment
  const clientLastCommitSuccess = data?.[dataContextVariableName]?.[unique_comment_id]?.last_comment_successful
  const pending = data?.[dataContextVariableName]?.[unique_comment_id]?.pending
  const onSend = async () => {
    console.log("sending")
    if (!comment) {
      toast("Comment is empty", BanSolid)
      return
    }

    if (commentPayload?.platform === "tiktok") {
      if (!data?.me?.brand?.is_tikapi_connected && !data?.me?.is_user_tikapi_connected) {
        toast("Need to connect your tiktok with zelf")
        api?.alert?.ConnectTiktokForLikeComment()
        return
      }
    }

    if (commentPayload?.platform === "youtube") {
      if (!data?.me?.brand?.is_youtube_connected) {
        toast("Need to connect your youtube with zelf")
        return
      }
    }

    api.common.updateManyObjectTypeInDataContent({
      id: unique_comment_id,
      dataContextVariableName: dataContextVariableName,
      newValue: { last_comment: comment, pending: true },
    })
    const d = await postComment({
      text: comment,
      content: commentPayload.content,
      platform: commentPayload?.platform,
      parent_comment: commentPayload.id,
      brand_id: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id,
    })
    if (!d) {
      api.common.updateManyObjectTypeInDataContent({
        id: unique_comment_id,
        dataContextVariableName: dataContextVariableName,
        newValue: { last_comment: comment, last_comment_successful: false, pending: false },
      })
      toast("Something went wrong... maybe the username does not exist")
      syncRestriction()
      return
    }
    setDailyStats({
      comment_status_count: d?.comment_status_count,
      comment_restriction: d?.comment_restriction,
    })
    api.common.updateManyObjectTypeInDataContent({
      id: unique_comment_id,
      dataContextVariableName: dataContextVariableName,
      newValue: { last_comment: comment, last_comment_successful: true, pending: false },
    })
    setComment("")
  }
  useEffect(() => {
    if (!ai_single_comment_payload?.comment) return
    if (!pullID) return

    if (ai_single_comment_payload?.id === pullID) {
      if (ai_single_comment_payload?.comment !== comment) {
        setComment(ai_single_comment_payload?.comment)
        setPullStart(false)
      }
    }
  }, [pullID, ai_single_comment_payload?.comment])

  const allLoading = ai_single_comment_loader || ai_loader || pollingStatus == "run"

  useEffect(() => {
    const _prev_ai_comments = commentPayload?.ai_generated_comments
    if (_prev_ai_comments?.length) {
      // console.log(_prev_ai_comments)
      const _comment = _prev_ai_comments.findLast(
        (x) => x.comment && x.parent_comment === unique_comment_id && !x.is_reject,
      )
      if (_comment) {
        setComment(_comment?.comment)
      }
    }
  }, [commentPayload])
  return (
    <div>
      <div className="flex ">
        <div className="full padding-x-3x margin-x-5x border-left-1 nc30-border margin-y-3x">
          <div className="flex a-center j-space-between">
            <SimpleCreatorInfo creator={commentPayload?.creator} className="margin-bottom-3x" />
            <HeartButton
              content_id={commentPayload?.content}
              border={false}
              isLiked={commentPayload?.is_liked}
              content_platform={commentPayload?.platform}
              parent_comment={commentPayload?.id}
            />
          </div>
          <p>{commentPayload.text}</p>
        </div>
        <div className="full flex  a-center j-center padding-3x">
          <div className="full">
            {(commentPayload?.replies ?? []).map((x, i) => (
              <PreviousComment
                headerText="Your reply"
                failedText="Reply failed"
                comment={x.text}
                key={i}
                className="margin-bottom-2x"
              />
            ))}
            {clientLastCommit ? (
              <PreviousComment
                headerText="Your reply"
                failedText="Reply failed"
                pending={pending}
                comment={clientLastCommit}
                className="margin-bottom-2x"
                failed={clientLastCommitSuccess === false}
              />
            ) : null}
            <AICommentDumbUI
              enableYoutube={connect_youtube_enabled}
              hideChatGPT={true}
              generateLoader={allLoading}
              postingCommentLoader={loader}
              onChatGPTClick={() => api?.alert?.playWithGPT(contentPayload)}
              platform={commentPayload?.platform}
              onSend={onSend}
              comment={comment}
              onGenerate={onRegenarate}
              setComment={setComment}
              commentEnable={true}
            />
          </div>
        </div>
      </div>
      <hr className="hr" />
    </div>
  )
}

const MassComments = ({ id, filter = "", contentPayload, className = "" }) => {
  const section = "mass_get_content_comments"
  const [data, dispatch] = useContext(dataContext)
  const { payload, loader, pagination } = useLocalBoundData({
    ...GET_CALLS[section],
    filter: {
      content_id: contentPayload?.content?.id,
    },
    skip: !contentPayload?.content?.id,
  })

  return (
    <div className={className}>
      {" "}
      <WrappedInLoader
        loader={loader}
        data={payload}
        noContentText="Comments processing; please check back later..."
        silent={pagination?.page >= 1}
      >
        {(payload ?? []).map((x, i) => (
          <SingleComment contentPayload={contentPayload} commentPayload={x} key={x.id} />
        ))}

        <div className="flex a-center j-center">
          {" "}
          {pagination?.hasNext ? (
            loader ? (
              <FullLoader />
            ) : (
              <Button disabled={loader} type="ghost" className="margin-2x" onClick={pagination.loadNext}>
                Load more comment
              </Button>
            )
          ) : null}
        </div>
      </WrappedInLoader>
    </div>
  )
}

const filterValue = [
  {
    title: "Recency",
    value: "scraped_timestamp,timestamp",
  },
  {
    title: "Oldest First",
    value: "scraped_timestamp,-timestamp",
  },
]

const EngageSection = ({ section }) => {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const [sortBy, setSortBy] = useState(filterValue[0].value)
  const { view_ai_generated_comment_only, view_sub_brand_ai_generated_comment } = useBrandConfig()
  const [sortAndTimeFrame, setSortAndTimeFrame] = useState({})
  const [filters, setFilters] = useState({})

  // hard coded value
  const additionalFilters = {}

  if (section === "mass_engage_all") {
    additionalFilters.is_owned_by_brand = false
  }
  const isPoliticalEngage = data?.me?.brand?.settings?.is_political_engage_enabled



  const {
    modified_payload,
    loader,
    content_stats_payload,
    showModal,
    pagination,
    cutoff,
    sDO,
    isGuest,
    filterQueryParam,
    detailOpen,
    isViewAllMode,
    onApply,
    pollingStatus,
  } = useFilteredContent({
    section: section,
    showFilter: true,
    additionalFilters,
    skipStats: true,
    overrideFilter: { sort_by: isPoliticalEngage ? `scraped_timestamp,randi` : sortBy },
    skipContentRequest: !data?.me?.brand,
    useExternalFilter: !isPoliticalEngage || section != "mass_engage_all",
    externalFilters: filters,
    setExternalFilters: setFilters,
    externalSortAndTimeFrame: sortAndTimeFrame,
    setExternalSortAndTimeFrame: setSortAndTimeFrame,
    stopAutoApiCall: false,
    // stopAutoApiCall: isPoliticalEngage && section == "mass_engage_all",
  })

  const [clientPostSideValues] = useAtom(clientSideContent)
  const _date_contents = contentByDay(
    modified_payload,
    "scraped_timestamp",
    section === "mass_engage_archive" ? {} : clientPostSideValues,
  )
  const { isRestricted } = useCommentRestrictionAndStats()

  const handleApply = (f) => {
    onApply(f)
    // if(isPoliticalEngage && section == "mass_engage_all"){
    //   onApply({...f, brand_id:data?.sub_brand_id})
    // }else{
    //   onApply(f)
    // }
  }

  // set pre applied filter for political engage
  useEffect(() => {
    // if(isPoliticalEngage && data?.sub_brand_id && section == "mass_engage_all"){
    if (false) {
      const _filter = data?.me?.brand?.sub_brands
        .find((x) => x.id == data?.sub_brand_id)
        ?.section_filters?.find((x) => x.section_name == "mass_engage_all")?.filter_query
      if (_filter) {
        let paramsObject = Object.fromEntries(new URLSearchParams(_filter).entries())
        if (data?.sub_brand_id == 1966 || data?.sub_brand_id == 1967) {
          paramsObject.timeframe = 3
        }
        setFilters(paramsObject)
        // console.log('paramsObject',paramsObject)
        onApply({ ...paramsObject, brand_id: data?.sub_brand_id }, true, true)
      } else {
        const paramsObject = { ai_comment_filter: "has_ai_generated_comment", brand_id: data?.sub_brand_id }
        if (data?.sub_brand_id == 1966 || data?.sub_brand_id == 1967) {
          paramsObject.timeframe = 3
        }
        setFilters(paramsObject), onApply(paramsObject, true, true)
      }
    }
  }, [data?.sub_brand_id])

  return (
    <>
      {(!isPoliticalEngage || data?.me?.user_group == 1) && (
        <div className="margin-y-5x">
          <ContentFilter
            showNotInfluencer={false}
            showAICommentFilter={!isPoliticalEngage}
            showIsNewFilter={false}
            preAppliedFilters={DISCOVERY_POST_SECTION_CONFIG[section]?.filters}
            showPostOnlyFiler={section === "all_posts"}
            onApply={handleApply}
            filters={filters}
            setFilters={setFilters}
            sortAndTimeFrame={sortAndTimeFrame}
            setSortAndTimeFrame={setSortAndTimeFrame}
            isPoliticalNPS={isPoliticalEngage}
          />
        </div>
      )}

      <WrappedInLoader
        noContentText="No content found"
        data={modified_payload}
        loader={loader}
        silent={pagination?.page >= 1}
      >
        {_date_contents?.dates.map((x, i) => {
          return (
            <ContentByDate
              restriction={isRestricted}
              showModal={showModal}
              showNoCommentButton={!(section === "mass_engage_archive")}
              titleElement={
                i == 0 && !isPoliticalEngage ? (
                  <Select
                    type="content"
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    styleType="capsule"
                    className=""
                  >
                    {filterValue.map((f, i) => (
                      <option key={i} value={f.value}>
                        {f.title}
                      </option>
                    ))}
                  </Select>
                ) : null
              }
              key={i}
              content={_date_contents?.contents?.[x]}
              date={x}
            />
          )
        })}

        <div className="full-span-grid-column">
          {" "}
          {pagination?.hasNext ? <ScrollSpy loader={loader} onEngage={pagination.loadNext} /> : null}
        </div>
      </WrappedInLoader>
    </>
  )
}

const OwnedContentEngage = ({ content, className = "", onThumbnailClick }) => {
  const [showComment, setShowComment] = useState(false)
  const [commentSection, setCommentSection] = useState("")
  const { isRestricted } = useCommentRestrictionAndStats()

  const handleThumbnailClick = () => {
    onThumbnailClick?.()
    setShowComment(false)
  }

  return (
    <Card className={`border-radius-8 body-regular-font nc75-fg    ${className}`}>
      <div className="flex padding-x-5x padding-y-3x">
        <div>
          <div className=" margin-right-5x">
            <TinyEcoSystemThumbnail
              hideCreatorInfo
              onClick={handleThumbnailClick}
              width="117"
              key={content.content.id}
              payload={content}
            />
          </div>
        </div>
        <div className="min-width-328 max-width-328 margin-right-3x">
          <div className="flex a-center padding-y-4x">
            <SimpleCreatorInfo creator={content.creator} className="" hideFollower creatorNameCutoff={20} />
            <DotSeparator className="margin-x" />
            <p className=" nc50-fg button-small-font">{dayjs(content.content.timestamp).format("DD MMMM YYYY")}</p>
          </div>
          <p className="nc50-fg caption margin-bottom-2x">caption</p>

          <ExpandableText
            text={content?.content?.text}
            lines={3}
            showMoreClasses={"txt-bold"}
            showLessClasses={"txt-bold"}
            showMoreText={"... see more"}
          />
        </div>
        <div>
          <p className="nc50-fg caption margin-bottom-2x">transcription</p>
          {content?.content?.transcription ? (
            <p>{content?.content?.transcription}</p>
          ) : (
            <span className="nc30-fg caption">transcription is not available at this moment</span>
          )}
        </div>
      </div>
      <hr className="hr " />
      <div className="padding-x-5x padding-y-3x flex j-space-between a-center">
        <ExpandButtonInline
          collapseText="Hide comments"
          trigger={showComment}
          onClick={() => setShowComment(!showComment)}
          fontClassName="nc100-fg headline-small-highlight"
          expandText="Comments"
        ></ExpandButtonInline>
        {showComment ? (
          <div className="flex a-center animation-fade entry">
            <Button
              onClick={() => setCommentSection("")}
              size="small"
              className="capsule-button flex vcenter margin-right-2x"
              type="tertiary"
            >
              <span className={`${commentSection === "" ? "primary-fg" : ""}`}>ALL</span>
            </Button>
            <Button
              onClick={() => setCommentSection("is_replied=true")}
              size="small"
              className="capsule-button flex vcenter margin-right-2x"
              type="tertiary"
            >
              <span className={`${commentSection === "is_replied=true" ? "primary-fg" : ""}`}>Answered</span>
            </Button>
            <Button
              onClick={() => setCommentSection("is_replied=false")}
              size="small"
              className="capsule-button flex vcenter margin-right-2x"
              type="tertiary"
            >
              <span className={`${commentSection === "is_replied=false" ? "primary-fg" : ""}`}>Open</span>
            </Button>
          </div>
        ) : null}
      </div>
      {content.content.id && showComment ? (
        <>
          <hr className="hr" />
          <MassComments
            className={isRestricted ? "pointer-event-none opacity-half" : ""}
            id={content.content.id}
            contentPayload={content}
            filter={commentSection}
          />
        </>
      ) : null}
    </Card>
  )
}

const OwnedPostSection = ({ section = "mass_engage_owned" }) => {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const {
    modified_payload,
    setFilters,
    loader,
    filters,
    content_stats_payload,
    showModal,
    pagination,
    cutoff,
    sDO,
    isGuest,
    filterQueryParam,
    detailOpen,
    isViewAllMode,
    sortAndTimeFrame,
    onApply,
    pollingStatus,
    setSortAndTimeFrame,
  } = useFilteredContent({ section: section, showFilter: true, skipStats: true })

  return (
    <div className="margin-top-5x">
      <WrappedInLoader
        noContentText="No content found"
        data={modified_payload}
        loader={loader}
        silent={data[`bd_sec_${section}_page`] > 1}
      >
        <>
          {modified_payload?.map((content, i) => {
            return (
              <OwnedContentEngage
                content={content}
                key={i}
                onThumbnailClick={() => {
                  // console.log("onclick")
                  showModal(content, modified_payload, i, null)
                }}
                className="margin-bottom-5x"
              />
            )
          })}
        </>
        <div className="">
          {" "}
          {pagination?.hasNext ? (
            <ScrollSpy rebindTrigger={pagination.next} loader={loader} onEngage={pagination.loadNext} />
          ) : null}
        </div>
      </WrappedInLoader>
    </div>
  )
}

const Reply = ({ replyPayload, className = "" }) => {
  const [show, setShow] = useState(false)
  const [data, dispatch] = useContext(dataContext)
  const {
    loader: replyLoader,
    payload: postPayload,
    executeCall: postReply,
  } = useBDPostData({ section: "add_comment_content", uniqueSectionPostfix: replyPayload?.id })
  const [reply, setReply] = useState("")
  const [lastComments, setLastComments] = useState([])
  const toast = useToast()

  const onSend = async () => {
    console.log("sending reply", reply)
    if (!reply) {
      toast("Can not send empty reply", BanSolid)
      return
    }

    if (replyPayload?.platform === "tiktok") {
      if (!data?.me?.brand?.is_tikapi_connected && !data?.me?.is_user_tikapi_connected) {
        toast("Need to connect your tiktok with zelf")
        api?.alert?.ConnectTiktokForLikeComment()
        return
      }
    }

    if (replyPayload?.platform === "youtube") {
      if (!data?.me?.brand?.is_youtube_connected) {
        toast("Need to connect your youtube with zelf")
        return
      }
    }

    const _reply = await postReply({
      text: reply,
      content: replyPayload?.content,
      parent_comment: replyPayload?.id,
      platform: replyPayload?.platform,
      brand_id: data?.sub_brand_id ? Number(data?.sub_brand_id) : data?.me?.brand?.id,
    })

    if (!_reply) {
      toast("SomeThing went wrong while comment", BanSolid)
      return
    }

    if (_reply) {
      setLastComments([...lastComments, reply])
    }
  }

  return (
    <div className={className}>
      <div className="flex j-space-between">
        <div>
          <SimpleCreatorInfo creator={replyPayload?.creator} className="margin-bottom" />
          <ExpandableText
            text={replyPayload?.text}
            lines={3}
            showMoreClasses={"txt-bold"}
            showLessClasses={"txt-bold"}
            showMoreText={"... see more"}
          />
        </div>
        <div className="margin-left">
          {replyLoader ? (
            <Loader></Loader>
          ) : (
            <div
              onClick={() => setShow(!show)}
              className="nc50-fg margin-bottom-4x cursor-pointer button-tiny-font txt-right"
              role="button"
            >
              Reply
            </div>
          )}

          <HeartButton
            className=""
            isLiked={replyPayload?.is_liked}
            content_id={replyPayload?.content}
            parent_comment={replyPayload?.id}
            content_platform={replyPayload?.platform}
            border={false}
            text={false}
          />
        </div>
      </div>
      {/* {lastComments?.map((x,i)=><div key={i} className='margin-y-2x'>
       <div className='flex j-space-between a-center nc75-fg'>
            <div className='flex a-center margin-left'>
                {data?.me?.brand?.title}
            </div>
        </div>
        <div className='caption-regular-font margin-top'>
            {x?.text}
        </div>

        </div>)} */}

      {show ? (
        <div className="margin-y-3x">
          <AICommentDumbUI
            onSend={onSend}
            comment={reply}
            setComment={setReply}
            postingCommentLoader={replyLoader}
            platform={replyPayload?.platform}
          ></AICommentDumbUI>
        </div>
      ) : null}
    </div>
  )
}

const SingleReplies = ({ contentPayload, onThumbnailClick }) => {
  const [loadComment, setLoadComment] = useState(false)
  const [data, dispatch] = useContext(dataContext)
  const { payload, loader } = useLocalBoundData({
    ...GET_CALLS.get_brand_comment_replied_comments,
    filter: { parent_comment_id: contentPayload?.content?.brand_comment_id },
    skip: !loadComment || !contentPayload?.content?.brand_comment_id,
    sub_brand_id: data?.sub_brand_id,
    platform: data?.discovery_section_platform,
  })
  const { ref, inView, entry } = useInView({
    /* Optional options */
    skip: loadComment,
  })

  useEffect(() => {
    if (inView) {
      setLoadComment(true)
    }
  }, [inView])

  return (
    <Card>
      <div ref={ref} className="flex padding-3x min-width-0 overflow-hidden">
        <div className="margin-right-3x">
          <TinyEcoSystemThumbnail onClick={onThumbnailClick} width="150" payload={contentPayload} hideCreatorInfo />
        </div>
        <div>
          <div>
            <SimpleCreatorInfo creator={contentPayload.creator} className="margin-bottom" />
          </div>
          <div className="body-regular-font nc75-fg ">
            <ExpandableText
              text={contentPayload?.content?.text}
              lines={3}
              className={"min-width-0"}
              showMoreClasses={"txt-bold"}
              showLessClasses={"txt-bold"}
              showMoreText={"... see more"}
            />
            {contentPayload?.content?.transcription ? (
              <>
                <div className="button-small-font nc75-fg margin-bottom-3x">Transcript</div>
                <ExpandableText
                  text={contentPayload?.content?.transcription}
                  lines={3}
                  className={"min-width-0"}
                  showMoreClasses={"txt-bold"}
                  showLessClasses={"txt-bold"}
                  showMoreText={"... see more"}
                />
              </>
            ) : null}
          </div>
        </div>
        <div></div>
      </div>
      <hr className="hr" />
      <div className="padding-3x">
        <div className="body-regular-font">
          {/* <SimpleCreatorInfo creator={contentPayload?.creator} className='margin-bottom'/> */}
          <div className="nc50-fg margin-bottom-2x">Your comment</div>
          <ExpandableText
            text={contentPayload?.content?.posted_comment}
            lines={3}
            showMoreClasses={"txt-bold"}
            showLessClasses={"txt-bold"}
            showMoreText={"... see more"}
          />
          <div className=" padding-left-3x border-left-2 nc30-border margin-top-3x">
            {loader ? (
              <>
                <ShimmerLoader className="margin-bottom-2x" />
                <ShimmerLoader className="margin-bottom-2x" />
                <ShimmerLoader />
              </>
            ) : (
              <>
                {payload?.length ? (
                  <>
                    {payload?.map((rep, i) => (
                      <Reply className="margin-bottom-2x" replyPayload={rep} key={i} />
                    ))}
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

const Replies = () => {
  const section = "get_brand_comment_replied_content"
  const [sortBy, setSortBy] = useState(filterValue[0].value)
  const [data, dispatch] = useContext(dataContext)
  const _filter = {
    has_posted_comment: true,
    has_brand_comment_replies: true,
  }
  const { payload, loader, pagination } = useLocalBoundData({
    ...GET_CALLS.get_brand_comment_replied_content,
    filter: { ..._filter, sort_by: sortBy },
    sub_brand_id: data?.sub_brand_id,
    platform: data?.discovery_section_platform,
  })
  const showModal = useModalHelper()
  const replies_post_by_date = contentByDay(payload ?? [], "scraped_timestamp")
  return (
    <div className="margin-y-2x">
      <WrappedInLoader
        data={payload}
        noContentText="No replies have been received yet. Please check back later."
        loader={loader}
        silent={pagination?.page >= 1}
      >
        {replies_post_by_date?.dates?.map((_d, i) => (
          <div key={i}>
            <div className="flex j-space-between a-center margin-top-2x  margin-bottom-4x">
              <h5 className="headline-small-highlight  ">{dayjs(_d).format("DD MMMM YYYY")}</h5>
              {i == 0 ? (
                <Select
                  type="content"
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  styleType="capsule"
                  className=""
                >
                  {filterValue.map((f, i) => (
                    <option key={i} value={f.value}>
                      {f.title}
                    </option>
                  ))}
                </Select>
              ) : null}
            </div>

            <Masonry columnsCount={2} gutter="15px">
              {replies_post_by_date.contents?.[_d]?.map((x, i) => (
                <SingleReplies
                  onThumbnailClick={() => showModal(x, payload, i, section)}
                  contentPayload={x}
                  key={i}
                  number={i}
                />
              ))}
            </Masonry>
          </div>
        ))}

        {pagination?.hasNext ? (
          <ScrollSpy rebindTrigger={pagination?.next} loader={loader} onEngage={pagination.loadNext} />
        ) : null}
      </WrappedInLoader>
    </div>
  )
}

const Engage = (props) => {
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  let [searchParams, setSearchParams] = useSearchParams()
  const [tabName, setTabName] = useState("main") // owned, main , overall, competitor , archive
  const toast = useToast()
  const { connect_youtube_enabled } = useBrandConfig()
  const { pathname } = useLocation()

  let section = data?.engage_tab_section_name
  const { payload: _comment_content_failed_stats } = useLocalBoundData({
    ...GET_CALLS.comment_status_count,
    filter: { timeframe: 365 },
    sub_brand_id: data?.sub_brand_id,
    skip: true,
  })
  const { isRestricted, restriction, syncDailyStats, dailyStats } = useCommentRestrictionAndStats()

  const posted_comment_stats = dailyStats

  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  let title = "Engage with Generative AI"

  const onSectionClick = (_sec) => {
    dispatch({ engage_tab_section_name: _sec })
    // setSection(_sec)
  }

  const setSubBrandId = (id, remove = false) => {
    const newSearchParams = new URLSearchParams(searchParams)
    if (remove) {
      newSearchParams.delete("sub_brand_id")
    } else {
      newSearchParams.set("sub_brand_id", id)
    }

    setSearchParams(newSearchParams)
  }

  const onMainBrandClick = () => {
    setTabName("main")
    setSection("mass_engage_all")
    setSubBrandId(null, true)
  }

  const isOwned = pathname?.startsWith("/engage/owned")
  const isArchive = pathname?.startsWith("/engage/archive")
  const isBoard = pathname?.startsWith("/engage/creator-boards")
  const isReplies = pathname?.startsWith("/engage/replies")

  if (isReplies) {
    title = "Replies"
  }

  useEffect(() => {
    syncDailyStats()
  }, [])

  return (
    <>
      <div className="brand-ecosystem-sub-tabs border-bottom-1 nc05-border nc00-bg">
        <div className=" flex content-area ">
          {!isPoliticalEngageEnabled && (
            <BrandTabLink selected={isOwned} href={"/engage/owned"} soft>
              {"Owned Content"}
            </BrandTabLink>
          )}
          <BrandTabLink
            onClick={onMainBrandClick}
            selected={pathname === "/engage"}
            href={`/engage${searchParams.get("sub_brand_id") && isPoliticalEngageEnabled ? "?sub_brand_id=" + searchParams.get("sub_brand_id") : ""}`}
            soft
          >
            {"Organic Content"}
          </BrandTabLink>
          {/* <BrandTabLink  selected={isBoard} href={"creator-boards"} soft>{"Detractors & Fans"}</BrandTabLink> */}
          {(!isPoliticalEngageEnabled || data?.me?.user_group == 1) && (
            <BrandTabLink
              className={``}
              selected={isReplies}
              href={`/engage/replies${searchParams.get("sub_brand_id") && isPoliticalEngageEnabled ? "?sub_brand_id=" + searchParams.get("sub_brand_id") : ""}`}
              soft
            >
              {"Replies"}
            </BrandTabLink>
          )}
          <BrandTabLink
            className={`margin-left-auto`}
            selected={isArchive}
            href={`/engage/archive${searchParams.get("sub_brand_id") && isPoliticalEngageEnabled ? "?sub_brand_id=" + searchParams.get("sub_brand_id") : ""}`}
            soft
          >
            {"Archive"}
          </BrandTabLink>
        </div>
      </div>

      {isOwned || isBoard ? null : <SubBrandFilterTabs className={"content-area margin-top-7x"} />}

      <div className="brand-ecosystem-content-area content-area">
        <div className={isBoard ? "" : "padding-y-10x"}>
          {isBoard ? null : (
            <div className="flex j-space-between a-center">
              <h2 className="headline-large">{title}</h2>
              {isOwned || isArchive ? (
                <DiscoveryPlatformFilter skipYoutube={!connect_youtube_enabled} />
              ) : (
                <div className="flex a-center">
                  <DiscoveryPlatformFilter skipYoutube={!connect_youtube_enabled} />
                  {isReplies
                    ? null
                    : sectionName.map((x, i) => (
                      <Button
                        className={`margin-x capsule-button `}
                        type="tertiary"
                        key={i}
                        onClick={() => onSectionClick(x.value)}
                      >
                        <span className={`${x?.value === section ? "primary-fg" : ""}`}>{x.name}</span>
                        {x.value === "mass_engage_failed" &&
                          posted_comment_stats?.comment_status_count?.comment_failed_count ? (
                          <>
                            <div className="" style={{ pointerEvents: "auto" }}>
                              <span
                                data-tip={`stats-comment-tooltip`}
                                data-for={`stats-comment-tooltip`}
                                className="flex cursor-pointer a-center negative-fg"
                              >
                                <DotSeparator className="margin-x-2x" />{" "}
                                {posted_comment_stats?.comment_status_count?.comment_failed_count}
                              </span>
                              {/* <ReactTooltip id={`stats-comment-tooltip`}>{}</ReactTooltip> */}
                            </div>
                          </>
                        ) : null}
                      </Button>
                    ))}
                </div>
              )}
            </div>
          )}
          <Outlet />
          {posted_comment_stats?.comment_status_count ? (
            <div className="position-fixed pointer-event-none" style={{ bottom: 80, left: 0, right: 0 }}>
              <div className="brand-ecosystem-content-area content-area flex">
                <div
                  style={{ transform: "translateX(-30%)" }}
                  className={`padding-x-5x padding-y-3x border-2 border-radius-capsule nc00-bg  flex a-center j-center button-large-font ${!isRestricted ? "nc10-border" : "negative-border"}`}
                >
                  <span>Engage</span>
                  <DotSeparator className="margin-x-2x" />
                  <div style={{ pointerEvents: "auto" }}>
                    <span data-tip data-for={"content-stats"} className="primary-fg cursor-pointer">
                      {" "}
                      {(posted_comment_stats?.comment_status_count?.posted_count ?? 0) +
                        (posted_comment_stats?.comment_status_count?.comment_posting_in_queue_count ?? 0) +
                        (posted_comment_stats?.comment_status_count?.scheduled_for_posting_count ?? 0)}{" "}
                    </span>
                    <ReactTooltip id={"content-stats"}>
                      Posted : {posted_comment_stats?.comment_status_count?.posted_count ?? 0}
                      <br />
                      In queue :{" "}
                      {(posted_comment_stats?.comment_status_count?.comment_posting_in_queue_count ?? 0) +
                        (posted_comment_stats?.comment_status_count?.scheduled_for_posting_count ?? 0)}
                      <br />
                      Failed : {posted_comment_stats?.comment_status_count?.comment_failed_count}
                    </ReactTooltip>
                  </div>

                  {!isRestricted ? (
                    <span className="nc50-fg margin-left">(Today)</span>
                  ) : (
                    <span className="margin-left negative-fg"> ( {restriction?.reason?.message} ) </span>
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}

export { Engage, OwnedPostSection, EngageSection, Replies }
