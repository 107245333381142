import { MUTATION_CALLS } from "../../../consts"
import { _nopropagate } from "../../../helpers/utils"
import { useLocalDataMutation } from "../../../hooks/useBoundData"
import { useToast } from "../../../hooks/useToast"
import { Card, ContentCloud, DotSeparator, ProfilePictureCloud, StatBlock } from "../../dumbs"
import { ArchiveLargeMonoOutline, RestoreIcon } from "../../Icons"
import { Loader } from "../../loaders"

function BoardSummary({ board, className = "", onClick, showArchiveIcon, onArchiveClick }) {
  const { executeCall, loader } = useLocalDataMutation({
    ...MUTATION_CALLS.update_board,
    endpoint: `/Board/${board?.id}`
  })

  const toast = useToast()
  const onArchiveOrRestore = async (e) => {
    _nopropagate(e)
    const res = await executeCall({
      is_archived: !board?.is_archived
    })
    if (res) {
      onArchiveClick(board)
    }


  }
  return (
    <Card onClick={onClick} className={`padding-5x cursor-pointer ${className}`}>
      <div className="campaign-summary-top-section flex spreaded">
        <div className="headline-medium-highlight flex a-center">{board.name} {board?.analytics_type ? <><DotSeparator className="margin-x-2x" /><span className="nc50-fg headline-small-highlight">Generated for AI Analysis</span></> : null}  </div>
        <div className="flex ">
          <ProfilePictureCloud total={board.total_creator} images={board?.profile_pictures ?? []} />
          {loader ? (

            <Loader className="margin-left-5x" />
          ) : (
            <div
              role="button"
              onClick={onArchiveOrRestore}
              className="margin-left-5x max-width-40 border-1 min-width-40 max-height-40 min-height-40 flex a-center j-center nc00-bg border-radius-round nc10-border nc50-fg cursor-pointer"
            >
              {showArchiveIcon ? ArchiveLargeMonoOutline : RestoreIcon}
            </div>
          )}
        </div>
      </div>
      <div className="campaign-summary-content-stats-section margin-top-6x flex spreaded">
        <div>
          <ContentCloud total={board.total_posts} cutoff={5} images={board?.thumbnails ?? []} />
        </div>
        <div>
          <div className="stat-section-campaign-summary flex vcenter">
            <StatBlock
              className="padding-right-4x"
              title={
                <>
                  Total <br />
                  creators
                </>
              }
              data={board?.total_creators}
            />
            <StatBlock
              className="padding-x-4x"
              title={
                <>
                  Total <br />
                  post
                </>
              }
              data={board?.total_posts}
            />
            <StatBlock
              className="padding-x-4x"
              title={
                <>
                  Total <br />
                  views
                </>
              }
              data={board?.total_views}
            />
            <StatBlock
              className="padding-left-4x"
              title={
                <>
                  Total <br />
                  engagement
                </>
              }
              data={board?.total_engagement}
            />
            <StatBlock
              className="padding-left-4x"
              separator={false}
              title={
                <>
                  Engagement <br />
                  rate
                </>
              }
              percentage
              data={board?.engagement_rate}
            />
            {/* <StatBlock title="Ads total" data={campaign?.total_ads}/> */}
            {/* <StatBlock title="Total views" data={86666}/> */}
          </div>
          <p className="txt-right nc30-fg caption-regular-font margin-top-2x">
            (does not include data for YouTube content)
          </p>
        </div>
      </div>
    </Card>
  )
}

export { BoardSummary }
