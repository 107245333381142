import React, { useContext, useState } from "react"
import { SimpleCreatorInfo } from "../contentThumbnail"
import { useBDBoundData } from "../../hooks/useBoundData"
import { AICommentDumbUI, WrappedInLoader } from "../dumbs"
import { AutoHeightTextarea } from "../input"
import { PlaneLargeFilledMono, RegenerateLarge, RegenerateSmall, SendComment, TimesSmallFilledMono } from "../Icons"
import { useAIComment, useBrandConfig, useCommentRestrictionAndStats, useContentModificationHelper, useGeneratedComment } from "../../hooks/helperHooks"
import { Loader } from "../loaders"
import { useToast } from "../../hooks/useToast"
import { dataContext } from "../../contexts/datacontext"
import { apiContext } from "../../contexts/apicontext"
import { COMMENT_STATE_MESSAGES } from "../../consts"

const ReplyComment = ({ className = "", creator, text = "", onReply }) => {
  return (
    <div className={`${className}`}>
      <div className="flex j-space-between a-center nc75-fg">
        <div className="flex a-center margin-left">
          <SimpleCreatorInfo
            creatorNameClass="button-tiny-font nc75-fg"
            creator={creator}
            creatorNameCutoff={30}
            hideFollower
          />
        </div>
        <div className="nc50-fg cursor-pointer button-tiny-font cursor-pointer" onClick={onReply}>
          Reply
        </div>
      </div>
      <div className="caption-regular-font margin-top">{text}</div>
    </div>
  )
}

const MyComment = ({ className = "", text = "", brandTitle = "", posting_state }) => {
  return (
    <div className={`${className}`}>
      <div className="flex j-space-between a-center nc75-fg">
        <div className="flex a-center">
          {brandTitle}{" "}
          {COMMENT_STATE_MESSAGES[posting_state] ? (
            <span className="chip margin-left-2x">
              <span className="caption-regular-font nc50-fg">{COMMENT_STATE_MESSAGES[posting_state]}</span>{" "}
            </span>
          ) : null}
        </div>
      </div>
      <div className="caption-regular-font margin-top">{text}</div>
    </div>
  )
}
const CommentThread = ({
  contentPayload,
  filter = "",
  isReadOnly = false,
}) => {
  const [data, dispatch] = useContext(dataContext)
  const id = contentPayload?.content?.id
  const content_brand_id = contentPayload?.content?.brand_id
  const api = useContext(apiContext)
  const { connect_youtube_enabled } = useBrandConfig()

  const isPoliticalEngageEnabled = data?.me?.brand?.settings?.is_political_engage_enabled

  const { payload, loader, pagination, internalSectionName } = useBDBoundData({
    section: "mass_get_content_comments",
    uniqueSectionPostfix: id,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    queryFilter: filter + `${isPoliticalEngageEnabled && data?.me ? "&user_id=" + data?.me?.id : ""}`,
  })
  const {
    payload: _owned_comment_payload,
    loader: owned_comment_loader,
    pagination: self_comment_pagination,
    internalSectionName: self_comment_section_name,
  } = useBDBoundData({
    section: id ? "content_modal_comments" : "",
    uniqueSectionPostfix: id,
    skipBrandRefresh: true,
    skipDiscoveryPlatformRefresh: true,
    queryFilter: filter + `${isPoliticalEngageEnabled && data?.me ? "&user_id=" + data?.me?.id : ""}`,
  })
  const [selectedReply, setSelectedReply] = useState(null)
  const { comment, setComment, postCurrentComment, postCommentLoader } = useContentModificationHelper({ content: contentPayload?.content, reply: selectedReply })
  const { onGenerate: gernerateComment, generateLoader } = useGeneratedComment({ content: contentPayload?.content, autoFetchPreviousAIComment: true })
  const toast = useToast()
  const { isRestricted, syncRestriction, setDailyStats } = useCommentRestrictionAndStats()



  let owned_comment_payload = (_owned_comment_payload ?? [])?.filter((c) => c?.has_failed === false)

  return (
    <div className={`flex column  full-height  nc02-bg ${isRestricted ? "opacity-half pointer-event-none" : ""} `}>
      <div className=" no-flex-shrink padding-x-5x padding-top-5x   j-space-between headline-small-highlight padding-bottom-5x">
        Comments
      </div>
      <div className=" full padding-x-5x overflow-auto ">
        <WrappedInLoader data={_owned_comment_payload} loader={owned_comment_loader}>
          {owned_comment_payload?.map((x, i) => (
            <MyComment
              key={i}
              text={x?.text}
              className="margin-bottom-2x"
              posting_state={x?.posting_state}
              brandTitle={data?.me?.brand?.title ?? data?.me?.brand?.name}
            />
          ))}
        </WrappedInLoader>
        <WrappedInLoader data={payload} loader={loader}>
          {Boolean(payload?.length) || Boolean(owned_comment_payload?.length) ? null : (
            <span className="caption nc30-fg">No comment was found for this post</span>
          )}
          {payload?.map((x, i) => (
            <ReplyComment
              onReply={() => setSelectedReply(x)}
              creator={x?.creator}
              text={x?.text}
              key={i}
              className="margin-bottom-2x"
            />
          ))}
        </WrappedInLoader>
      </div>
      {selectedReply ? (
        <div className="padding-x-5x padding-y-3x  no-flex-shrink nc00-bg nc75-fg">
          <div className="flex j-space-between a-center caption">
            <div className="">Replying to</div>
            <div className="flex a-center j-center">
              <SimpleCreatorInfo
                creatorNameClass="button-tiny-font nc75-fg"
                className="margin-right"
                creator={selectedReply?.creator}
                creatorNameCutoff={30}
                hideFollower
              />
              <span className="nc30-fg cursor-pointer" onClick={() => setSelectedReply(null)}>
                {TimesSmallFilledMono}
              </span>
            </div>
          </div>
          <p className="margin-top-3x caption nc75-fg">{selectedReply?.text}</p>
        </div>
      ) : null}
      {(!isReadOnly && (data?.me?.brand?.is_tikapi_connected || data?.me?.is_user_tikapi_connected || isPoliticalEngageEnabled)) ? (
        <div className=" no-flex-shrink nc00-bg flex a-center border-top-1 nc10-border">
          <AICommentDumbUI
            enableYoutube={connect_youtube_enabled}
            hideChatGPT={selectedReply}
            commentEnable={!isPoliticalEngageEnabled}
            approveEnable={data?.me?.brand?.is_tikapi_connected || data?.me?.is_user_tikapi_connected}
            comment={comment}
            generateLoader={generateLoader}
            onChatGPTClick={() => api?.alert?.playWithGPT(undefined, contentPayload)}
            platform={contentPayload?.content?.content_platform}
            setComment={setComment}
            postingCommentLoader={postCommentLoader}
            onSend={postCurrentComment}
            onGenerate={() => {
              gernerateComment()
            }}
            noBorder={true}
          />
        </div>
      ) : null}
    </div>
  )
}

export { CommentThread }
