import React, { useContext, useEffect, useState } from "react"
import { AutoHeightTextarea, Input } from "../input"
import { dataContext } from "../../contexts/datacontext"
import { Button } from "../button"
import { useBDPostData } from "../../hooks/useBoundData"
import { useToast } from "../../hooks/useToast"
import { apiContext } from "../../contexts/apicontext"
import { Loader } from "../loaders"
import { SimpleCreatorInfo, TinyEcoSystemThumbnail } from "../contentThumbnail"
import { Card, WrappedInLoader } from "../dumbs"
import { useAIComment, useGeneratedComment } from "../../hooks/helperHooks"
import { ChatgptIcon } from "../Icons"

const AIBrandChatSettings = (props) => {
  const [data, dispatch] = useContext(dataContext)

  const brand_ai_settings = data?.brand_ai_settings ?? {}
  useEffect(() => {
    if (!data?.me?.brand) return
    const _settings = {
      category: data?.me?.brand?.category ?? "",
      brand_type: data?.me?.brand?.brand_type ?? "",
      brand_motto: data?.me?.brand?.brand_motto ?? "",
      tones: (data?.me?.brand?.tones ?? []).join(", ") ?? "",
    }

    dispatch({
      brand_ai_settings: {
        ...brand_ai_settings,
        ..._settings,
      },
    })
  }, [data?.me?.brand])

  const updateValue = (value, field) => {
    dispatch({
      brand_ai_settings: {
        ...brand_ai_settings,
        [field]: value,
      },
    })
  }

  return (
    <div className="txt-left nc100-fg ">
      <p className="body-regular-font margin-bottom-5x nc100-fg">
        Set your brand tone in order to effectively generate comments that match your brand’s personality.
      </p>
      <div className="margin-bottom-5x display-block">
        <div className="margin-bottom-2x button-medium-font">What is your brand category/ industry?</div>
        <Input
          value={brand_ai_settings?.category}
          onChange={(e) => updateValue(e.target.value, "category")}
          className=""
          placeholder={`e.g. tech, retail, etc...`}
        ></Input>
      </div>
      <div className="margin-bottom-5x display-block">
        <div className="margin-bottom-2x button-medium-font">What is your brand tone?</div>
        <Input
          value={brand_ai_settings?.tones}
          onChange={(e) => updateValue(e.target.value, "tones")}
          className=""
          placeholder={`e.g. make the world a better place...`}
        ></Input>
      </div>
      <div className="margin-bottom-5x display-block">
        <div className="margin-bottom-2x button-medium-font">What is your brand motto?</div>
        <textarea
          value={brand_ai_settings?.brand_motto}
          onChange={(e) => updateValue(e.target.value, "brand_motto")}
          className={`margin-bottom input-element button-medium-font margin-bottom text-area-resize-none `}
          placeholder={`e.g. friendly, not extreme, ...`}
        ></textarea>
      </div>
    </div>
  )
}

const UpdateBrandAISettingsAction = ({ contentPayload }) => {
  // update_self_brand
  const { loader, executeCall } = useBDPostData({ section: "update_self_brand" })
  const [data, dispatch] = useContext(dataContext)
  const api = useContext(apiContext)
  const settings = data?.brand_ai_settings ?? {}
  const toast = useToast()

  const onSave = async () => {
    if (!settings?.category || !settings?.brand_motto || !settings?.tones) {
      toast("Brand tones cannot be empty", null)
      return
    }
    const tones = (settings.tones ?? "")
      ?.split(",")
      ?.map((x) => x.trim())
      ?.filter((x) => Boolean(x))
    if (!tones?.length) {
      toast("Brand tones cannot be empty", null)
      return
    }

    const payload = {
      category: settings?.category,
      brand_motto: settings?.brand_motto,
      tones,
    }
    // const res = await executeCall(payload)
    if (true) {
      api.brand.me()
      toast("Brand tones updated")
      api?.alert?.playWithGPT(contentPayload)
    }
  }

  return (
    <Button
      onClick={() => {
        onSave()
      }}
      className="margin-left-2x"
      disabled={loader}
    >
      {loader ? <Loader className="margin-right-2x" /> : null}
      Save
    </Button>
  )
}

const PlayWithGPT = ({ contentPayload, originalContentPayload }) => {

  const { selectedGeneratedComment, onGenerate, generateLoader } = useGeneratedComment({
    content: originalContentPayload?.content,
    autoFetchPreviousAIComment: true
  })

  const [modificationText, setModificationText] = useState("")



  const handleKeyPress = async (event) => {
    if (event.key === "Enter") {
      if (!selectedGeneratedComment) return
      await onGenerate({
        prompt: modificationText,
        parent_ai_comment_id: selectedGeneratedComment?.id,
      })
      setModificationText("")
    }
  }
  return (
    <div className="flex padding-5x j-center">
      <TinyEcoSystemThumbnail hideStats hideCreatorInfo payload={originalContentPayload} width="400" />
      <Card className="padding-3x min-width-400 max-width-400 txt-left">
        <SimpleCreatorInfo
          creator={originalContentPayload?.creator}
          creatorNameCutoff={100}
          className="margin-bottom-4x"
        />
        <Card className={"padding-3x max-height-318 overflow-auto txt-left margin-bottom-4x"}>
          <div>Video transcript:</div>
          <p className={originalContentPayload?.content?.transcription ? "nc100-fg" : "nc30-fg"}>
            {originalContentPayload?.content?.transcription
              ? originalContentPayload?.content?.transcription
              : "transcript is not available at this moment."}
          </p>
        </Card>
        <div className="txt-left nc100-fg">
          <Card className={`nc02-bg ${selectedGeneratedComment ? "" : "action-disabled-faded"}`}>
            <div className="padding-3x"> {selectedGeneratedComment?.comment} </div>
            <hr className="hr" />
            <WrappedInLoader loader={generateLoader} data={{}}>
              <div className="padding-3x nc02-fg flex nc30-fg">
                {ChatgptIcon}
                <AutoHeightTextarea
                  className="nc02-bg margin-left"
                  value={modificationText}
                  placeholder={`modify comment with command...`}
                  onChange={(e) => setModificationText(e.target.value)}
                  onKeyPress={handleKeyPress}
                />
              </div>
            </WrappedInLoader>
          </Card>
        </div>
      </Card>
    </div>
  )
}

export { AIBrandChatSettings, UpdateBrandAISettingsAction, PlayWithGPT }
