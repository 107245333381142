import PropTypes from "prop-types"
import ConfettiImage from "./../../assets/confetti.jpg"
import zlogo from "../../assets/zelflogo.png"
import { Button } from "../button"
import { OnboardingCreatorContainer } from "../onboadingCommon"
import React, { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import { _lsr, _jp, _lsd } from "../../helpers/utils"
import { useLocalDataMutation } from "../../hooks/useBoundData"
import { MUTATION_CALLS } from "../../consts"
import { FullLoader } from "../loaders"
import { useLoading } from "../../contexts/loaderContext"
import { Steps } from "../dumbs"



const OnboardingCreatorStepDescription = (props) => {
  const navigate = useNavigate()
  const api = useContext(apiContext)
  const [data, dispatch] = useContext(dataContext)
  const brandName = data?.brand_ecosystem_brand?.name ?? "Brands"
  const { executeCall: activateVolunteer, loader: ActiveVolunteerloader } = useLocalDataMutation(MUTATION_CALLS.active_as_volunteer)
  const { loaders } = useLoading()

  let steps = [
    "Connect your social accounts",
    "Fill out your profile information",
    `Get invited to a ${data?.brand_ecosystem_brand?.name ?? "Brand"} campaign`,
    "Get paid swift and simply",
  ]
  const invite_config = _lsr("invite_config")
  if (invite_config?.steps) steps = invite_config?.steps

  const onContinueClick = () => {
    navigate("/start-social")
  }

  useEffect(() => {
    const _brand = data?.brand_ecosystem_brand
    if (!_brand?.is_political_engage_enabled) return
    (async () => {
      const res = await activateVolunteer({
        brand_id: _brand?.id
      })
      if (res) {
        _lsd("brand-ecosystem-invite-refcode")
        window.open('/', '_self');
      }
    })()

  }, [data?.brand_ecosystem_brand])

  if (ActiveVolunteerloader || loaders["accept_brand_invite_info"]) {
    return <FullLoader />
  }

  return (
    <OnboardingCreatorContainer cardClassName="padding-y-11x">
      <h2 className="headline-large max-width-456 txt-center margin-bottom-10x">
        Let’s setup your content creation profile for {brandName}
      </h2>

      <div className="max-width-318">
        {steps.map((text, i) => (
          <Steps key={i} className="margin-bottom-4x" number={i + 1} text={text} />
        ))}

        <Button className="max-width-328 margin-y-10x" fluid size="large" onClick={onContinueClick}>
          Start Setup
        </Button>
      </div>
    </OnboardingCreatorContainer>
  )
}

export { OnboardingCreatorStepDescription }
