import { getSubtractedDate, moveIdsToFront, num2Hip } from "../../helpers/utils"
import { useBDBoundData, useLocalBoundData } from "../../hooks/useBoundData"
import { Card, WrappedInLoader } from "../dumbs"
import { ToggleWithTitle } from "../toggle"
import { BarChart, DonutChart, NotEnoughData, SmallBarNegativeChart } from "../ui/graphs"
import Chart from "react-apexcharts"
import { timeseriesdataparcer } from "./brandStats"
import { Select } from "../select"
import { useContext, useState } from "react"
import { ArrowDown } from "../Icons"
import { set } from "lodash"
import { dataContext } from "../../contexts/datacontext"
import { POLITICAL_CANDIDATE_NAME_SERIAL, POLITICAL_VP_CANDIDATES } from "../../consts"
import { GET_CALLS } from "../../consts"
import { FullLoader } from "../loaders"

const processNPSData = (data) => {
  if (!data || !data.length) return

  const npsDonutData = []
  const categories = []
  const totalViewsData = {
    positive: [],
    negative: [],
  }
  const totalEngagementData = {
    positive: [],
    negative: [],
  }

  const totalCreatorData = {
    positive: [],
    negative: [],
  }

  const totalPrioritisedContentData = {
    positive: [],
    negative: [],
  }

  const totalContentData = []
  const tikTokViewsData = []

  moveIdsToFront(data, POLITICAL_CANDIDATE_NAME_SERIAL, (x) => x.brand_title).forEach((item) => {
    const totalNPS = item.total_positive_nps + item.total_negative_nps + item.total_neutral_nps

    npsDonutData.push({
      name: item.brand_title,
      data: [
        totalNPS > 0 ? parseFloat(((item.total_positive_nps / totalNPS) * 100).toFixed(2)) : 0,
        totalNPS > 0 ? parseFloat(((item.total_neutral_nps / totalNPS) * 100).toFixed(2)) : 0,
        totalNPS > 0 ? parseFloat(((item.total_negative_nps / totalNPS) * 100).toFixed(2)) : 0,
      ],
    })
    categories.push(item.brand_title)

    totalViewsData.positive.push(item.total_positive_views)
    totalViewsData.negative.push(
      item.total_negative_views > 0 || item.total_positive_views <= 0 ? -item.total_negative_views : null,
    )

    totalEngagementData.positive.push(item.total_positive_engagement)
    totalEngagementData.negative.push(
      item.total_negative_engagement > 0 || item.total_positive_engagement <= 0
        ? -item.total_negative_engagement
        : null,
    )

    totalCreatorData.positive.push(item.total_positive_creator_count)
    totalCreatorData.negative.push(
      item.total_negative_creator_count > 0 || item.total_positive_creator_count <= 0
        ? -item.total_negative_creator_count
        : null,
    )

    totalPrioritisedContentData.positive.push(item.total_positive_prioritised_content)
    totalPrioritisedContentData.negative.push(
      item.total_negative_prioritised_content > 0 || item.total_positive_prioritised_content <= 0
        ? -item.total_negative_prioritised_content
        : null,
    )

    totalContentData.push({
      x: `#${item.hard_code_hashtag}`,
      // x: item.brand_title,
      y: Math.abs(item.total_tiktok_content_count ?? 0),
    })

    tikTokViewsData.push({
      x: `#${item.hard_code_hashtag}`,
      // x: item.brand_title,
      y: Math.abs(item.total_tiktok_views ?? 0),
    })
  })

  return {
    npsDonutData,
    categories,
    totalViewsData,
    totalEngagementData,
    totalCreatorData,
    totalContentData,
    tikTokViewsData,
    totalPrioritisedContentData,
  }
}

const processTrendingIssuesData = (data, key) => {
  if (!data || !data.length) return
  const categories = []
  const series = []

  data
    .sort((a, b) => b[key] - a[key])
    .forEach((item) => {
      categories.push(item.brand_title)
      series.push(item[key])
    })

  return {
    categories,
    series: [
      {
        name: key.charAt(0).toUpperCase() + key.slice(1).replace("_", " "),
        data: series,
      },
    ],
  }
}

const processEngagementPerIssueData = (data) => {
  if (!data || !data.length) return
  const categories = []
  const kamalaPosEngagement = []
  const trumpPosEngagement = []

  const kamalaNegEngagement = []
  const trumpNegEngagement = []

  const kamalaTotalPosts = []
  const trumpTotalPosts = []

  data
    .sort((a, b) => b.kamala_positive_engagement - a.kamala_positive_engagement)
    .forEach((item) => {
      categories.push(item.brand_title)
      kamalaPosEngagement.push(item?.kamala_positive_engagement ?? 0)
      trumpPosEngagement.push(item?.trump_positive_engagement ?? 0)

      kamalaNegEngagement.push(-(item?.kamala_negative_engagement ?? 0))
      trumpNegEngagement.push(-(item?.trump_negative_engagement ?? 0))

      kamalaTotalPosts.push(item?.kamala_positive_total_content ?? 0)
      trumpTotalPosts.push(item?.trump_positive_total_content ?? 0)
    })

  return {
    categories,
    totalEngagementData: [
      {
        name: "Kamala Harris",
        data: kamalaPosEngagement,
      },
      {
        name: "Donald Trump",
        data: trumpPosEngagement.map((item, index) => {
          return item > 0 || kamalaPosEngagement[index] <= 0 ? -item : null
        }),
      },
    ],
    totalPostsData: [
      {
        name: "Kamala Harris",
        data: kamalaTotalPosts,
      },
      {
        name: "Donald Trump",
        data: trumpTotalPosts.map((item, index) => {
          return item > 0 || kamalaTotalPosts[index] <= 0 ? -item : null
        }),
      },
    ],
    kamalaEngagementData: [
      {
        name: "Positive",
        data: kamalaPosEngagement,
      },
      {
        name: "Negative",
        data: kamalaNegEngagement,
      },
    ],
    trumpEngagementData: [
      {
        name: "Positive",
        data: trumpPosEngagement,
      },
      {
        name: "Negative",
        data: trumpNegEngagement,
      },
    ],
  }
}

const NPSGraphs = ({
  fromDate,
  toDate,
  timeFrame = 7,
  onMarkerClick,
  showNpsGraphs = true,
  showPrioritizedGraphs = true,
  showTimeFrameSubText = false,
  hideIfNoData = false,
}) => {
  const [data] = useContext(dataContext)

  const competitors =
    data?.me?.brand?.sub_brands?.filter((item) => item.sub_brand_type == 1)?.map((item) => item.id) ?? []

  const { payload, loader: loading } = useBDBoundData({
    section: "political_stats",
    skipBrandRefresh: true,
    queryFilter: fromDate && toDate ? `post_date__gte=${fromDate}&post_date__lte=${toDate}` : `timeframe=${timeFrame}`,
    skipDiscoveryPlatformRefresh: true,
  })

  const _data = processNPSData(payload)

  const handleBarChartClick = (data) => {
    const brand = payload.find((item) => item.brand_title === data.category)
    if (!data || !brand) return

    // const candidateKey = data.category === 'Joe Biden' ? 'biden_nps' : 'trump_nps'

    let _data = {
      originalValue: {
        ...brand,
        brand: brand?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        timeframe: timeFrame,
        // [candidateKey]: data.type === 'positive' ? '1' : data.type === 'negative' ? '-1' : '0'
      },
      excludeFilters: {
        timestamp__gte: true,
        timestamp__lte: true,
        exc_comment_mentioned: true,
      },
      is_competitor: competitors.includes(brand?.brand_id),
      nps_score: data.type === "positive" ? "1" : data.type === "negative" ? "-1" : "0",
    }

    if (!fromDate && !toDate) {
      _data.excludeFilters = {
        ..._data.excludeFilters,
        post_date__gte: true,
        post_date__lt: true,
      }
    }

    onMarkerClick?.(_data)
  }

  const handleDonutChartClick = (candidate, data, index) => {
    const brand = payload.find((item) => item.brand_title === data.name)
    if (!brand || !data) return
    const candidateKey = candidate === "Joe Biden" ? "biden_nps" : "trump_nps"
    let _data = {
      originalValue: {
        ...brand,
        brand: brand?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        // ...filters,
        timeframe: timeFrame,
        // [candidateKey]: index === 0 ? '1' : index === 1 ? '-1' : '0'
      },
      excludeFilters: {
        timestamp__gte: true,
        timestamp__lte: true,
        exc_comment_mentioned: true,
      },
      is_competitor: competitors.includes(brand?.brand_id),
      nps_score: index === 0 ? "1" : index === 1 ? "0" : "-1",
    }

    if (!fromDate && !toDate) {
      _data.excludeFilters = {
        ..._data.excludeFilters,
        post_date__gte: true,
        post_date__lt: true,
      }
    }

    onMarkerClick?.(_data)
  }

  const handlePNBarChartClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return

    // map candidate from dataPointIndex
    const mapCandidate = (index) => {
      if (index === 0 || index === 4) return "Joe Biden"
      if (index === 1 || index === 5) return "Donald Trump"
      if (index === 2 || index === 6) return "Kamala Harris"
    }

    const brand = payload.find((item) => item.brand_title === mapCandidate(config?.dataPointIndex))
    if (!brand) return

    const _data = {
      originalValue: {
        ...brand,
        brand: brand?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        timeframe: timeFrame,
      },
      excludeFilters: {
        timestamp__gte: true,
        timestamp__lte: true,
        exc_comment_mentioned: true,
      },
      is_competitor: competitors.includes(brand?.brand_id),
      nps_score: config.seriesIndex === 0 ? "1" : "-1",
    }

    onMarkerClick?.(_data)
  }

  if (hideIfNoData && loading) {
    return <FullLoader />
  }
  // currently hardcoded key total_tiktok_content_count & total_tiktok_views check for main dashboard
  if (hideIfNoData && (!payload?.length || payload?.every((item) => item.total_tiktok_content_count === 0 && item.total_tiktok_views === 0))) {
    return null
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="">
          <WrappedInLoader data={payload} loader={loading}>
            <BarChart
              title="# of posts on TikTok"
              subtitle={showTimeFrameSubText ? `(last ${timeFrame} days)` : ""}
              className={`full full-height`}
              data={_data?.totalContentData ? _data?.totalContentData : null}
              colors={["#6BC7EE", "#6BC7EE", "#6BC7EE"]}
              enableYAxis={true}
              options={{
                yaxis: {
                  show: true,
                  labels: {
                    formatter: (x) => num2Hip(x),
                    style: {
                      colors: ["#767B8A"],
                    },
                  },
                },
                grid: {
                  borderColor: "#F1F2F3",
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }}
            />
          </WrappedInLoader>
        </div>
        <div className="">
          <WrappedInLoader data={payload} loader={loading}>
            <BarChart
              title="Views on TikTok"
              subtitle={showTimeFrameSubText ? `(last ${timeFrame} days)` : ""}
              className={`full full-height`}
              data={_data?.tikTokViewsData ? _data?.tikTokViewsData : null}
              colors={["#6BC7EE", "#6BC7EE", "#6BC7EE"]}
              enableYAxis={true}
              options={{
                yaxis: {
                  show: true,
                  labels: {
                    formatter: (x) => num2Hip(x),
                    style: {
                      colors: ["#767B8A"],
                    },
                  },
                },
                grid: {
                  borderColor: "#F1F2F3",
                  xaxis: {
                    lines: {
                      show: false,
                    },
                  },
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
              }}
            />
          </WrappedInLoader>
        </div>
      </div>
      {showNpsGraphs && (
        <div className={`margin-top-4x grid gap-4 grid-cols-${_data?.npsDonutData?.length ?? 1}`}>
          <WrappedInLoader data={payload} loader={loading}>
            {_data?.npsDonutData?.length ? (
              <>
                {_data?.npsDonutData?.map((item, index) => {
                  return (
                    <DonutChart
                      title={`${item?.name} NPS`}
                      data={item?.data}
                      colors={["#32EAC9", "#FFE37F", "#F67979"]}
                      labels={["Positive", "Neutral", "Negative"]}
                      height={220}
                      centerLabelOffsetY={90}
                      centerLabel={`${parseInt(item?.data[0] - item?.data[2])}%`}
                      key={index}
                      onDonutClick={(index) => handleDonutChartClick(item?.name, item, index)}
                    />
                  )
                })}
              </>
            ) : (
              <Card>
                <NotEnoughData />
              </Card>
            )}
          </WrappedInLoader>
        </div>
      )}
      {showPrioritizedGraphs && (
        <div className="margin-top-4x grid grid-cols-4 gap-4">
          <WrappedInLoader data={_data?.totalViewsData} loader={loading}>
            <SmallBarNegativeChart
              title="Views prioritized posts"
              data={
                _data?.totalViewsData
                  ? { positive: _data?.totalViewsData?.positive, negative: _data?.totalViewsData?.negative }
                  : null
              }
              categories={_data?.categories}
              onBarClick={handleBarChartClick}
              height={230}
              enableCenterZero={true}
            />
          </WrappedInLoader>
          <WrappedInLoader data={_data?.totalEngagementData} loader={loading}>
            <SmallBarNegativeChart
              title="Engagements prioritized posts"
              data={
                _data?.totalEngagementData
                  ? { positive: _data?.totalEngagementData?.positive, negative: _data?.totalEngagementData?.negative }
                  : null
              }
              categories={_data?.categories}
              onBarClick={handleBarChartClick}
              height={230}
              enableCenterZero={true}
            />
          </WrappedInLoader>
          <WrappedInLoader data={_data?.totalCreatorData} loader={loading}>
            <SmallBarNegativeChart
              title="# of TikTok prioritized creators"
              data={
                _data?.totalCreatorData
                  ? { positive: _data?.totalCreatorData?.positive, negative: _data?.totalCreatorData?.negative }
                  : null
              }
              categories={_data?.categories}
              onBarClick={handleBarChartClick}
              height={230}
              enableCenterZero={true}
            />
          </WrappedInLoader>
          <WrappedInLoader data={_data?.totalPrioritisedContentData} loader={loading}>
            <SmallBarNegativeChart
              title="# of TikTok prioritized posts"
              data={
                _data?.totalPrioritisedContentData
                  ? {
                    positive: _data?.totalPrioritisedContentData?.positive,
                    negative: _data?.totalPrioritisedContentData?.negative,
                  }
                  : null
              }
              categories={_data?.categories}
              onBarClick={handleBarChartClick}
              height={230}
              enableCenterZero={true}
            />
          </WrappedInLoader>
        </div>
      )}
    </>
  )
}

const processCandidatesComparativeAttentionData = (data, type, timeFrame) => {
  if (!data || !data.length) return

  const categories = []
  const bidenData = []
  const trumpData = []
  const harrisData = []
  const rfkData = []
  const vanceData = []
  const timData = []

  for (let i = 0; i < timeFrame; i++) {
    const date = new Date()
    date.setDate(date.getDate() - (timeFrame - i - 1))

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const dateStr = `${year}-${month}-${day}`
    categories.push(dateStr)

    const dateData = data.filter((item) => item.date === dateStr)
    if (dateData.length === 0) {
      bidenData.push(0)
      trumpData.push(0)
      harrisData.push(0)
    } else {
      const biden = dateData.find((item) => item.brand_title === "Joe Biden")
      const trump = dateData.find((item) => item.brand_title === "Donald Trump")
      const harris = dateData.find((item) => item.brand_title === "Kamala Harris")
      const rfkJr = dateData.find((item) => item.brand_title === "RFK Jr")
      const vance = dateData.find((item) => item.brand_title === "JD Vance")
      const tim = dateData.find((item) => item.brand_title === "Tim Walz")
      bidenData.push(biden?.[type] ?? 0)
      trumpData.push(trump?.[type] ?? 0)
      harrisData.push(harris?.[type] ?? 0)
      rfkData.push(rfkJr?.[type] ?? 0)
      vanceData.push(vance?.[type] ?? 0)
      timData.push(tim?.[type] ?? 0)
    }
  }

  return {
    categories,
    series: [
      {
        name: "Kamala Harris",
        data: harrisData,
      },
      {
        name: "Trump",
        data: trumpData,
      },
      // {
      //     name: 'Biden',
      //     data: bidenData
      // },
      // {
      //   name: "RFK Jr",
      //   data: rfkData,
      // },
      {
        name: "JD Vance",
        data: vanceData,
      },
      {
        name: "Tim Walz",
        data: timData,
      },
    ],
  }
}

const ComparativeAttentionCandidatesChart = ({ timeFrame = 30, onLineClick }) => {
  const [type, setType] = useState("rolling_avg_nps")
  const [dataType, setDataType] = useState("rolling_avg")

  const { payload, loader: loading } = useBDBoundData({
    section: "candidates_comparative_attention",
    skipBrandRefresh: true,
    queryFilter: `timeframe=${timeFrame}`,
    skipDiscoveryPlatformRefresh: true,
  })

  const _data = processCandidatesComparativeAttentionData(payload, type, timeFrame)

  const handleLineClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return

    const date = _data?.categories?.[config?.dataPointIndex]
    const seriesName = _data?.series?.[config?.seriesIndex]?.name

    const originalValue = payload.find(
      (item) => item.date === date && item?.brand_title?.toLowerCase().includes(seriesName.toLowerCase()),
    )

    if (!originalValue) return

    onLineClick?.({
      originalValue: {
        ...originalValue,
        brand: originalValue?.brand_id,
        is_sub_brand: true,
      },
      start_date: date,
      days_to_add: 1,
    })
  }

  return (
    <div className="margin-top-4x ">
      <Card className={`padding-y-5x padding-x-5x full `}>
        <div className="flex vcenter j-space-between margin-bottom-4x">
          <h3 className=" body-highlight-font">
            Comparative Attention - Candidates <span className="font-regular">(7 days rolling average)</span>
          </h3>
          <ul className="flex vcenter j-end gap-2">
            <li>
              <button
                className={`cursor-pointer transparent-bg transparent-border ${type === "rolling_avg_nps" ? "nc75-fg" : "nc30-fg"}`}
                onClick={() => setType("rolling_avg_nps")}
              >
                NPS
              </button>
            </li>
            <span className="nc10-fg body-small-font">/</span>
            <li>
              <button
                className={`cursor-pointer transparent-bg transparent-border ${type === "rolling_avg_views" ? "nc75-fg" : "nc30-fg"}`}
                onClick={() => setType("rolling_avg_views")}
              >
                Views
              </button>
            </li>
            <span className="nc10-fg body-small-font">/</span>
            <li>
              <button
                className={`cursor-pointer transparent-bg transparent-border ${type === "rolling_avg_engagement" ? "nc75-fg" : "nc30-fg"}`}
                onClick={() => setType("rolling_avg_engagement")}
              >
                Engagements
              </button>
            </li>
          </ul>
          {/* <ToggleWithTitle
                        firstTitle="Rolling average"
                        lastTitle="Daily data"
                        className=""
                        value={dataType == 'daily_data'}
                        onClick={()=>setDataType(dataType === 'rolling_avg' ? 'daily_data' : 'rolling_avg')}
                    /> */}
        </div>
        <WrappedInLoader data={[]} loader={loading}>
          {!_data || payload?.length === 0 ? (
            <NotEnoughData />
          ) : (
            <Chart
              series={_data?.series}
              options={{
                chart: {
                  type: "line",
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  events: {
                    click: handleLineClick,
                  },
                },
                colors: [
                  "#3354FF",
                  "#FF1010",
                  // "#F1C93B",
                  "#FF1010",
                  "#3354FF"
                ],
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  width: 3,
                  dashArray: [
                    0,
                    0,
                    // 0,
                    4,
                    4
                  ],
                },
                xaxis: {
                  categories: _data?.categories,
                  axisBorder: {
                    color: "#F1F2F3",
                  },
                },
                yaxis: {
                  min: type == "rolling_avg_nps" ? -1 : undefined,
                  max: type == "rolling_avg_nps" ? 1 : undefined,
                  labels: {
                    formatter: (x) => (type == "rolling_avg_nps" ? `${(x * 100).toFixed(1)}%` : num2Hip(x)),
                  },
                },
                tooltip: {},
                grid: {
                  show: true,
                  borderColor: "#F1F2F3",
                  yaxis: {
                    lines: {
                      show: true,
                    },
                  },
                  xaxis: {
                    lines: {
                      show: true,
                    },
                  },
                },
                legend: {
                  offsetY: 8,
                },
              }}
              height={350}
            />
          )}
        </WrappedInLoader>
      </Card>
    </div>
  )
}

const TrendingIssuesChart = ({
  timeFrame = 30,
  onMarkerClick,
  title = "Top trending issues",
  className = "",
  filterFunc,
  hideWhenNoData = false,
  additional_filter = {},
}) => {
  const [dataKey, setDataKey] = useState("total_engagement")

  const { payload: original_payload, loader: loading } = useLocalBoundData({
    ...GET_CALLS.trending_issues,
    filter: { timeframe: timeFrame, ...additional_filter },
  })
  let payload = original_payload ?? []
  if (filterFunc && payload?.length) {
    payload = payload?.filter(filterFunc)
  }

  const _data = processTrendingIssuesData(payload, dataKey)

  const onBarClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return

    const _data = {
      originalValue: {
        ...payload[config?.dataPointIndex],
        brand: payload[config?.dataPointIndex]?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        timeframe: timeFrame,
      },
      excludeFilters: {
        post_date__gte: true,
        post_date__lt: true,
        exc_comment_mentioned: true,
      },
      days_to_add: timeFrame,
    }

    onMarkerClick?.(_data)
  }
  if (hideWhenNoData && !payload?.length) {
    return null
  }

  return (
    <div className={`${className}`}>
      <Card className={`padding-y-5x padding-x-5x full `}>
        <div className="flex j-space-between">
          <div className="flex vcenter margin-bottom-4x gap-3">
            <h3 className="body-highlight-font">{title}</h3>
            <span className="body-thin-font">
              ({new Date().toLocaleDateString("en-US", { month: "long", day: "numeric" })})
            </span>
          </div>
          {/* <ToggleWithTitle
                        firstTitle="Total engagement"
                        lastTitle="Total posts"
                        className=""
                        value={dataKey == 'total_post'}
                        onClick={()=>setDataKey(dataKey === 'total_engagement' ? 'total_post' : 'total_engagement')}
                    /> */}
          <Select
            type="content"
            styleType="ghost"
            onChange={(e) => setDataKey(e.target.value)}
            value={dataKey}
            className="sort-filter margin-x-2x margin-left-auto"
            presentationalBitClass="padding-bottom-2x-important padding-top-2x-important  nc50-fg-override"
            noBorder
            dropdownIcon={ArrowDown}
          >
            {[
              { title: "Total engagement", value: "total_engagement" },
              { title: "Total posts", value: "total_post" },
              { title: "Total views", value: "total_views" },
              { title: "Total likes", value: "total_likes" },
              { title: "Total comments", value: "total_comments" },
              { title: "Total shares", value: "total_shares" },
            ].map((_srt) => (
              <option key={_srt?.value} value={_srt?.value}>
                {_srt?.title}
              </option>
            ))}
          </Select>
        </div>
        <WrappedInLoader data={payload} loader={loading}>
          {!_data || payload?.series?.length === 0 ? (
            <NotEnoughData />
          ) : (
            <Chart
              options={{
                chart: {
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                  // animations:{
                  //     enabled:false
                  // },
                  zoom: {
                    enabled: false,
                  },
                  events: {
                    click: onBarClick,
                    legendClick: () => { },
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "60%",
                    dataLabels: {
                      position: "top", // top, center, bottom ,
                      hideOverflowingLabels: false,
                    },
                  },
                },
                dataLabels: {
                  enabled: true,
                  offsetY: -20,
                  style: {
                    fontSize: "12px",
                    colors: ["#767B8A"],
                  },
                  formatter: function (val) {
                    return num2Hip(val)
                  },
                },
                xaxis: {
                  categories: _data?.categories,
                  labels: {
                    // rotate: -90,
                    // minHeight: 160,
                  },
                },
                colors: ["#663FF1", "#9273FF", "#C5B4FF", "#E3DBFF"],
                stroke: {
                  width: [0, 0, 0, 0, 3],
                  // colors: ['#fff', '#fff', '#fff', "#663FF1"]
                },
                yaxis: {
                  title: {
                    text: dataKey.charAt(0).toUpperCase() + dataKey.slice(1).replace("_", " "),
                    offsetX: 3,
                  },
                  labels: {
                    formatter: (x) => num2Hip(x),
                  },
                },
                legend: {
                  // formatter: (val, _) => val === "Rolling Average" ? "" : val,
                  // markers: {
                  //     width: [12,12,12,12,0]
                  // }
                },
              }}
              series={_data?.series}
              height={400}
              type="bar"
            />
          )}
        </WrappedInLoader>
      </Card>
    </div>
  )
}

const EngagementPerIssueChart = ({ timeFrame = 30, onMarkerClick, filterFunc }) => {
  const [dataKey, setDataKey] = useState("total_engagement")
  const { payload: original_payload, loader: loading } = useBDBoundData({
    section: "engagement_per_issue",
    skipBrandRefresh: true,
    queryFilter: `timeframe=${timeFrame}`,
    skipDiscoveryPlatformRefresh: true,
  })

  let payload = original_payload ?? []
  if (filterFunc && payload?.length) {
    payload = payload?.filter(filterFunc)
  }

  const _data = processEngagementPerIssueData(payload)

  const handleIssueChartClick = (event, chartContext, config) => {
    const _data = payload[config?.dataPointIndex]
    if (!_data) return

    const filters = config?.seriesIndex === 0 ? { kamala_nps: 1 } : { trump_nps: 1 }
    const data = {
      originalValue: {
        ..._data,
        brand: _data?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        ...filters,
        timeframe: timeFrame,
      },
      excludeFilters: {
        post_date__gte: true,
        post_date__lt: true,
        exc_comment_mentioned: true,
      },
      days_to_add: timeFrame,
    }
    onMarkerClick?.(data)
  }

  const handleCandidateIssueEngagementClick = (candidate, seriesIndex, dataPointIndex) => {
    const _data = payload[dataPointIndex]
    if (!_data) return

    const filters =
      candidate === "Kamala Harris"
        ? { kamala_nps: seriesIndex === 0 ? 1 : -1 }
        : { trump_nps: seriesIndex === 0 ? 1 : -1 }

    const data = {
      originalValue: {
        ..._data,
        brand: _data?.brand_id,
        is_sub_brand: true,
      },
      filters: {
        ...filters,
        timeframe: timeFrame,
      },
      excludeFilters: {
        post_date__gte: true,
        post_date__lt: true,
      },
      days_to_add: timeFrame,
    }
    onMarkerClick?.(data)
    // console.log(_data, candidate, seriesIndex, dataPointIndex)
  }

  return (
    <>
      <div className="margin-top-4x">
        <Card className={`padding-y-5x padding-x-5x full `}>
          <div className="flex j-space-between vcenter margin-bottom-4x">
            <div className="flex vcenter gap-3">
              <h3 className="body-highlight-font">Engagement per issue</h3>
              <span className="body-thin-font">
                ({new Date().toLocaleDateString("en-US", { month: "long", day: "numeric" })})
              </span>
            </div>
            <div className="flex gap-3 caption-regular nc50-fg">
              <div className="flex vcenter gap-2">
                <span className="min-height-10 min-width-10 rb80-bg border-radius-2"></span>
                <span className="">Kamala Harris</span>
              </div>
              <div className="flex vcenter gap-2">
                <span className="min-height-10 min-width-10 rb40-bg border-radius-2"></span>
                <span className="">Donald Trump</span>
              </div>
            </div>
            <ToggleWithTitle
              firstTitle="Total engagement"
              lastTitle="Total posts"
              className=""
              value={dataKey == "total_post"}
              onClick={() => setDataKey(dataKey === "total_engagement" ? "total_post" : "total_engagement")}
            />
          </div>
          <WrappedInLoader data={payload} loader={loading}>
            {!_data || _data?.totalEngagementData?.[0]?.length === 0 ? (
              <NotEnoughData />
            ) : (
              <Chart
                options={{
                  chart: {
                    type: "bar",
                    stacked: true,
                    toolbar: {
                      show: false,
                    },
                    events: {
                      click: handleIssueChartClick,
                    },
                  },
                  colors: ["#663FF1", "#DBD1FF"],
                  plotOptions: {
                    bar: {
                      borderRadius: 0,
                      borderRadiusApplication: "end", // 'around', 'end'
                      borderRadiusWhenStacked: "all", // 'all', 'last'
                      horizontal: false,
                      barHeight: "50%",
                      dataLabels: {
                        position: "top", // top, center, bottom
                        hideOverflowingLabels: false,
                      },
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    offsetY: -20,
                    formatter: function (val) {
                      return typeof val == "number" ? num2Hip(val > 0 ? val : -val) : ""
                    },
                    style: {
                      fontSize: "12px",
                      colors: ["#767B8A"],
                    },
                  },
                  stroke: {
                    width: 1,
                    colors: ["#fff"],
                  },
                  legend: {
                    show: false,
                  },
                  grid: {
                    borderColor: "#F1F2F3",
                    xaxis: {
                      lines: {
                        show: false,
                      },
                    },
                    yaxis: {
                      lines: {
                        show: true,
                      },
                    },
                  },
                  yaxis: {
                    stepSize: 1,
                    show: true,
                    title: {
                      text: dataKey === "total_engagement" ? "Total Engagement" : "Total Posts",
                    },
                    labels: {
                      formatter: (x) => num2Hip(x > 0 ? x : -x),
                    },
                  },
                  xaxis: {
                    categories: _data?.categories,
                    axisTicks: {
                      show: false,
                    },
                    axisBorder: {
                      show: false,
                    },
                    labels: {
                      formatter: function (val) {
                        return val
                      },
                      // rotate: -90,
                    },
                  },

                  tooltip: {
                    shared: false,
                    y: {
                      formatter: function (val) {
                        return num2Hip(val > 0 ? val : -val)
                      },
                    },
                  },
                }}
                series={dataKey === "total_engagement" ? _data?.totalEngagementData : _data?.totalPostsData}
                type="bar"
                height={450}
              />
            )}
          </WrappedInLoader>
        </Card>
      </div>
      <div className="grid grid-cols-2 gap-4 margin-top-4x">
        <Card className={`padding-top-5x padding-x-5x full `}>
          <div className="flex j-space-between margin-bottom-2x">
            <span className="rb100-fg body-highlight-font">Kamala Harris</span>
            <span className="body-small-font">
              total engagement ({new Date().toLocaleDateString("en-US", { month: "long", day: "numeric" })})
            </span>
          </div>
          <WrappedInLoader data={payload} loader={loading}>
            {!_data || _data?.kamalaEngagementData?.[0]?.length === 0 ? (
              <NotEnoughData />
            ) : (
              <TotalEngagementChart
                categories={_data?.categories}
                series={_data?.kamalaEngagementData}
                height={150 + _data?.categories?.length * 20}
                onMarkerClick={(seriesIndex, dataPointIndex) =>
                  handleCandidateIssueEngagementClick("Kamala Harris", seriesIndex, dataPointIndex)
                }
              />
            )}
          </WrappedInLoader>
        </Card>
        <Card className={`padding-top-5x padding-x-5x full `}>
          <div className="flex j-space-between margin-bottom-2x">
            <span className="rb100-fg body-highlight-font">Donald Trump</span>
            <span className="body-small-font">
              total engagement ({new Date().toLocaleDateString("en-US", { month: "long", day: "numeric" })})
            </span>
          </div>
          <WrappedInLoader data={payload} loader={loading}>
            {!_data || _data?.trumpEngagementData?.[0]?.length === 0 ? (
              <NotEnoughData />
            ) : (
              <TotalEngagementChart
                categories={_data?.categories}
                series={_data?.trumpEngagementData}
                height={150 + _data?.categories?.length * 20}
                onMarkerClick={(seriesIndex, dataPointIndex) =>
                  handleCandidateIssueEngagementClick("Donald Trump", seriesIndex, dataPointIndex)
                }
              />
            )}
          </WrappedInLoader>
        </Card>
      </div>
    </>
  )
}

const ComparativeAttentionThemesChart = ({ timeFrame = 30, onMarkerClick }) => {
  const [isCandidates, setCandidates] = useState(false)
  const { payload, loader: loading } = useBDBoundData({
    section: "timeseries_brand_total_engagement",
    skipBrandRefresh: true,
    queryFilter: `timeframe=${timeFrame}&post_type=ALL&sub_brand_type=${isCandidates ? 3 : 2}`,
    skipDiscoveryPlatformRefresh: true,
  })

  const series = timeseriesdataparcer(payload, "total_engagement", undefined, timeFrame, false, {})

  const handleClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return
    // console.log(config?.dataPointIndex, config?.seriesIndex, series[config?.seriesIndex]?.data?.[config?.dataPointIndex])
    const _data = series[config?.seriesIndex]?.data?.[config?.dataPointIndex]
    onMarkerClick?.(_data)
  }

  return (
    <div className="margin-top-4x">
      <Card className={`padding-y-5x padding-x-5x full `}>
        <div className="flex a-center j-space-between margin-bottom-4x">
          <h3 className="body-highlight-font">Comparative Attention ({!isCandidates ? "Issues" : "Candidates"})</h3>
          <div>
            <ToggleWithTitle
              firstTitle="Issues"
              lastTitle="Candidates"
              className=""
              value={isCandidates}
              onClick={() => setCandidates(!isCandidates)}
            />
          </div>
        </div>
        <WrappedInLoader data={series} loader={loading}>
          {!series || series?.length === 0 ? (
            <NotEnoughData />
          ) : (
            <Chart
              series={series}
              options={{
                chart: {
                  stacked: true,
                  toolbar: {
                    show: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  events: {
                    click: handleClick,
                    legendClick: () => { },
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                markers: {
                  enabled: false,
                  strokeWidth: 1,
                  hover: {
                    size: 4,
                  },
                },
                colors: [
                  "#FF5733",
                  "#FF8D1A",
                  "#FFC300",
                  "#DAF7A6",
                  "#BFFF00",
                  "#66FF66",
                  "#33FFCC",
                  "#1AC6FF",
                  "#337DFF",
                  "#6633FF",
                  "#CC33FF",
                  "#FF33CC",
                  "#FF6699",
                  "#FF9980",
                  "#FFB266",
                  "#FFD480",
                  "#FFF680",
                  "#DFFF80",
                  "#80FFBF",
                  "#80E5FF",
                ],
                stroke: {
                  width: 1, // Adjust the stroke width
                  curve: "straight",
                  lineCap: "round", // Make the end of the line rounded
                },
                // title: {
                //     text: 'Comparative attention',
                //     align: 'left'
                // },
                grid: {
                  borderColor: "#e7e7e7",
                  row: {
                    colors: ["#ffffff"], // takes an array which will be repeated on columns
                  },
                },
                xaxis: {
                  type: "datetime",
                },
                yaxis: {
                  labels: { formatter: (x) => num2Hip(x) },
                  title: {
                    text: "Total Engagement",
                  },
                  // min: 5,
                  // max: 40
                },
                legend: {
                  position: "bottom",
                  // floating: true,
                },
                tooltip: {
                  custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    const timestamp = w.globals.initialSeries[0].data[dataPointIndex].x
                    const date = new Date(timestamp).toLocaleDateString("en-GB", {
                      day: "numeric",
                      month: "long",
                    })
                    const data = w.globals.series.map((s, i) => ({
                      name: w.globals.seriesNames[i],
                      value: s[dataPointIndex],
                      color: w.config.colors[i],
                    }))

                    // Sort the data array by value
                    data.sort((a, b) => b.value - a.value)

                    // Create the tooltip content
                    let tooltipContent = `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${date}</div>`
                    data.forEach((d) => {
                      tooltipContent += `
                                                <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;">
                                                    <span class="apexcharts-tooltip-marker" style="background-color: ${d.color};"></span>
                                                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                                                        <div class="apexcharts-tooltip-y-group">
                                                            <span class="apexcharts-tooltip-text-y-label">${d.name}: </span>
                                                            <span class="apexcharts-tooltip-text-y-value">${num2Hip(d.value)}</span>
                                                        </div>
                                                    </div>
                                                </div>`
                    })

                    return tooltipContent
                  },
                },
              }}
              type="area"
              height={350}
            />
          )}
        </WrappedInLoader>
      </Card>
    </div>
  )
}

const TotalEngagementChart = ({
  height = 650,
  categories = [
    "Healthcare Reform",
    "Immigration Policy",
    "Economic Policy",
    "Foreign Policy",
    "Climate Change",
    "Gun Control",
    "Education Policy",
    "Criminal Justice Reform",
    "Social Security",
    "Tax Policy",
    "Trade Policy",
    "Infrastructure",
    "National Security",
    "Civil Rights",
    "Labor Policy",
    "Agriculture Policy",
    "Veterans Affairs",
    "Housing Policy",
    "Energy Policy",
    "Transportation Policy",
    "Science Policy",
    "Technology Policy",
    "Telecommunications Policy",
    "Space Policy",
    "Water Policy",
    "Waste Management",
    "Forestry Policy",
    "Fisheries Policy",
    "Mining Policy",
  ],
  series = [],
  onMarkerClick,
}) => {
  const handleClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return
    onMarkerClick?.(config?.seriesIndex, config?.dataPointIndex)
  }

  return (
    <Chart
      series={series}
      options={{
        chart: {
          type: "bar",
          stacked: true,
          toolbar: {
            show: false,
          },
          dataLabels: {
            // position: 'top', // top, center, bottom
            enabled: false,
          },
          events: {
            click: handleClick,
          },
        },
        colors: ["#32EAC9", "#F67979"],
        plotOptions: {
          bar: {
            borderRadius: 0,
            borderRadiusApplication: "end", // 'around', 'end'
            borderRadiusWhenStacked: "all", // 'all', 'last'
            horizontal: true,
            barHeight: "60%",
          },
        },
        dataLabels: {
          enabled: false,
          // offsetX: 0,
          // offsetY: -20,
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        legend: {
          show: false,
        },
        grid: {
          borderColor: "#F1F2F3",
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          stepSize: 1,
          show: true,
          labels: {
            offsetX: -5,
            align: "left",
            minWidth: 180,
            maxWidth: 520,
            formatter: function (val, opts) {
              if (typeof val !== "string") {
                return val
              }
              // console.log(series[0].data[opts.dataPointIndex] + series[1].data[opts.dataPointIndex], val, series[0].data[opts.dataPointIndex], series[1].data[opts.dataPointIndex], opts)
              const diff = series[0].data[opts.dataPointIndex] + series[1].data[opts.dataPointIndex]
              return isNaN(diff) ? val : `${val} (${diff > 0 ? `+${num2Hip(diff)}` : `-${num2Hip(-diff)}`})`
            },
            // style: {
            //     cssClass: 'text-blue-500',
            // }
          },
        },
        xaxis: {
          position: "top",
          show: true,
          categories,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          labels: {
            formatter: function (val) {
              return num2Hip(val > 0 ? val : -val)
            },
          },
        },
        tooltip: {
          shared: false,
          x: {
            formatter: function (val) {
              return val
            },
          },
          y: {
            formatter: function (val) {
              return num2Hip(val > 0 ? val : -val)
            },
          },
        },
      }}
      type="bar"
      height={height}
    />
  )
}

const PoliticalDashboard = () => {
  const [duration, setDuration] = useState("7")
  const [data, dispatch] = useContext(dataContext)

  const openContentPopup = (data) => {
    // console.log(data)
    if (!data?.originalValue) {
      toast("No data on that day")
      return
    }
    // api?.alert?.dataPointContentPopup(data)

    dispatch({
      date_specific_dialog_data: data,
    })
  }

  return (
    <div className="content-area">
      <div className="flex margin-top-8x margin-bottom-5x vcenter j-space-between">
        <h2 className="headline-medium-large">Candidate Overview</h2>
        <Select
          type="content"
          styleType="ghost"
          onChange={(e) => setDuration(e.target.value)}
          value={duration}
          className="sort-filter margin-x-2x margin-left-auto"
          presentationalBitClass="padding-bottom-2x-important padding-top-2x-important  nc50-fg-override"
          noBorder
          dropdownIcon={ArrowDown}
        >
          {[
            { value: "2", title: "Last 24 hours" },
            { value: "7", title: "Last 7 days" },
            { value: "15", title: "Last 15 days" },
            { value: "30", title: "Last 30 days" },
            { value: "60", title: "Last 60 days" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select>
      </div>
      <NPSGraphs timeFrame={duration} onMarkerClick={openContentPopup} />
      <ComparativeAttentionCandidatesChart timeFrame={duration} onLineClick={openContentPopup} />
      <TrendingIssuesChart
        hideWhenNoData={false}
        timeFrame={duration}
        onMarkerClick={openContentPopup}
        additional_filter={{ sub_brand_type: 3, fe_section: "vp_graph" }}
        title="Top trending Kamala surrogates"
        className="margin-top-4x"
      />
      <h2 className="headline-medium-large margin-top-8x margin-bottom-4x ">Issues Overview</h2>
      <TrendingIssuesChart
        timeFrame={duration}
        onMarkerClick={openContentPopup}
        className="margin-top-4x"
        additional_filter={{ sub_brand_type: 2 }}
      />
      <EngagementPerIssueChart timeFrame={duration} onMarkerClick={openContentPopup} />
      <ComparativeAttentionThemesChart timeFrame={duration} onMarkerClick={openContentPopup} />
    </div>
  )
}

export { PoliticalDashboard, NPSGraphs }
