import React, { useContext, useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { apiContext } from "../../contexts/apicontext"
import { dataContext } from "../../contexts/datacontext"
import {
  formatDate,
  generateDateArray,
  getMonthStartAndEndDateBasedOnCurrentMonth,
  getStartDateOfWeekBasedOnCurrentWeek,
  getSubtractedDate,
  num2Hip,
} from "../../helpers/utils"
import { useLocalBoundData } from "../../hooks/useBoundData"
import { useToast } from "../../hooks/useToast"
import { Card, WrappedInLoader } from "../dumbs"
import { Select } from "../select"
import { BarChart, DonutChart, NotEnoughData } from "../ui/graphs"
import { Navigate } from "react-router-dom"
import { GET_CALLS } from "../../consts"
import { NPSGraphs } from "./politicalDashboard"
import { DateRangePicker } from "../input"
import dayjs from "dayjs"

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
}

const genarateBrandColor = (brand) => {
  const mainBrandColor = `#663FF1`
  const _colors = ["#43D37D", "#E70C89", "#FFC839", "#FFA300", "#FF0000", "#0CAFA0", "#0033FF", "#00FFFF"]
  const brandthemeConfig = { [brand.id]: { color: mainBrandColor } }

  const sub_brands = brand?.sub_brands ?? []
  let _cindex = 0

  for (let sub_brand of sub_brands) {
    brandthemeConfig[sub_brand.id] = { color: _colors[_cindex] }
    _cindex = _colors.length > _cindex + 1 ? _cindex + 1 : 0
  }

  return brandthemeConfig
}

const KVMDataParcer = (allData = [], fieldName) => {
  if (!allData) return
  const mainBrand = allData?.find((x) => !x.is_sub_brand)

  let _data = mainBrand
    ? [mainBrand, ...allData.filter((x) => x.is_sub_brand)]
    : [...allData.filter((x) => x.is_sub_brand)]

  if (fieldName === "nps") {
    // if (
    //   (!mainBrand?.total_neutral_nps && mainBrand?.total_neutral_nps !== 0) ||
    //   (!mainBrand?.total_positive_nps && mainBrand?.total_positive_nps !== 0) ||
    //   (!mainBrand?.total_negative_nps && mainBrand?.total_negative_nps !== 0)
    // ) {
    //   return null
    // }
    const positive = mainBrand?.total_positive_nps ?? 0
    const negative = mainBrand?.total_negative_nps ?? 0
    const neutral = mainBrand?.total_neutral_nps ?? 0

    const _total = positive + negative + neutral
    if (_total == 0) return null

    let nps = [
      (positive / _total).toFixed(4) * 100,
      (negative / _total).toFixed(4) * 100,
      (neutral / _total).toFixed(4) * 100,
    ]
    // nps = nps.map(x=>x.toFixed(2))
    return nps
  }

  return _data.map((brand) => {
    return {
      x: brand?.brand_title ?? brand?.brand_name,
      y: brand?.[fieldName],
    }
  })
}

export const timeseriesdataparcer = (
  allData,
  fieldName,
  brand_id,
  numberOfDays = 60,
  skipFillZero = false,
  colorConfig,
  period = "day",
) => {
  if (!allData) return
  const _data = {}

  for (let d of allData) {
    const date = period === "week" ? getStartDateOfWeekBasedOnCurrentWeek(d.week) : d.date

    if (!_data?.[d?.brand_name]?.data) {
      _data[d.brand_name] = {
        data: [
          {
            x: date,
            y: d[fieldName],
            originalValue: d,
          },
        ],
        name: d?.brand_title ?? d?.brand_name,
        color: colorConfig?.[d?.brand]?.color,
      }
    } else {
      _data[d.brand_name].data.push({
        x: date,
        y: d[fieldName],
        originalValue: d,
      })
    }
  }
  if (!skipFillZero) {
    for (let e in _data) {
      _data[e].data = generateDateArray(numberOfDays, _data[e].data)
    }
  }
  const _d = Object.keys(_data)?.map((brand_name) => ({ ..._data[brand_name], data: _data[brand_name]?.data }))

  return _d
}

const mentionParser = (allData, time) => {
  if (!allData) return
  const total_post_mentions = []
  const total_comment_mentions = []
  const category = []

  if (time === "timeframe=365&period=month") {
    allData.sort((a, b) => {
      return a.year - b.year
    })
  }

  allData.forEach((x, i) => {
    total_comment_mentions.push(x.total_comment_mentions)
    total_post_mentions.push(x.total_post_mentions)
    category.push(
      time === "timeframe=365&period=month"
        ? monthMap[x.month]
        : time == "timeframe=84&period=week"
          ? formatDate(getStartDateOfWeekBasedOnCurrentWeek(x.week))
          : x.date,
    )
  })

  let data = {
    series: [
      {
        name: "Mentioned in post",
        data: total_post_mentions,
      },
      {
        name: "Mentioned in comment",
        data: total_comment_mentions,
      },
    ],
    category,
  }

  return data
}

const engagementParser = (allData, time, activeLegends) => {
  if (!allData) return
  const totalLikes = []
  const totalComments = []
  const totalShares = []
  const rollingAverage = []
  const category = []

  if (time === "timeframe=365&period=month") {
    //sort by month & year both
    allData.sort((a, b) => {
      if (a.year < b.year) return -1
      if (a.year > b.year) return 1
      return a.month - b.month
    })
  } else if (time === "timeframe=84&period=week") {
    allData.sort((a, b) => {
      return a.week - b.week
    })
  } else {
    // 2024-06-05 this is the date format
    allData.sort((a, b) => {
      return new Date(a.date) - new Date(b.date)
    })
  }
  allData.forEach((element, i) => {
    totalLikes.push(element.total_likes)
    totalComments.push(element.total_comments)
    totalShares.push(element.total_shares)
    category.push(
      time === "timeframe=365&period=month"
        ? `${monthMap[element.month]}`
        : time == "timeframe=84&period=week"
          ? formatDate(getStartDateOfWeekBasedOnCurrentWeek(element.week))
          : element.date,
    )

    // if activeLegends has 0,1,3 then show all data
    // if activeLegends has 0,1 then show only sum of likes and comments
    // if activeLegends has 0,2 then show only sum of likes and shares
    // if activeLegends has 1,2 then show only sum of comments and shares
    // if activeLegends has 0 then show only likes
    // if activeLegends has 1 then show only comments
    // if activeLegends has 2 then show only shares
    // if activeLegends has nothing then empty the data

    if (activeLegends.length === 3) {
      rollingAverage.push(
        (element.rolling_avg_likes + element.rolling_avg_comments + element.rolling_avg_shares)?.toFixed(2),
      )
    } else if (activeLegends.length === 2) {
      if (activeLegends.includes(0) && activeLegends.includes(1)) {
        rollingAverage.push((element.rolling_avg_likes + element.rolling_avg_comments)?.toFixed(2))
      } else if (activeLegends.includes(0) && activeLegends.includes(2)) {
        rollingAverage.push((element.rolling_avg_likes + element.rolling_avg_shares)?.toFixed(2))
      } else if (activeLegends.includes(1) && activeLegends.includes(2)) {
        rollingAverage.push((element.rolling_avg_comments + element.rolling_avg_shares)?.toFixed(2))
      }
    } else if (activeLegends.length === 1) {
      if (activeLegends.includes(0)) {
        rollingAverage.push(element.rolling_avg_likes?.toFixed(2))
      } else if (activeLegends.includes(1)) {
        rollingAverage.push(element.rolling_avg_comments?.toFixed(2))
      } else if (activeLegends.includes(2)) {
        rollingAverage.push(element.rolling_avg_shares?.toFixed(2))
      }
    } else {
      rollingAverage.push(0)
    }
    // rollingAverage.push(time === "timeframe=84&period=week" ? element.rolling_avg_all_weekly?.toFixed(2) : element.rolling_avg_all?.toFixed(2))
  })

  // dummy data for days
  // if(time == "timeframe=12"){
  //     for (let i = 0; i < 31; i++) {
  //         category.push(i + 1)
  //         totalLikes.push(Math.floor(Math.random() * 1000000) + 1)
  //         totalComments.push(Math.floor(Math.random() * 1000000) + 1)
  //         totalShares.push(Math.floor(Math.random() * 1000000) + 1)
  //         rollingAverage.push(Math.floor(Math.random() * 1000000) + 1)
  //     }
  // }

  return {
    series: [
      {
        name: "Likes",
        data: totalLikes,
        type: "bar",
      },
      {
        name: "Comments",
        data: totalComments,
        type: "bar",
      },
      {
        name: "Shares",
        data: totalShares,
        type: "bar",
      },
      // {
      //     name: "Rolling Average",
      //     data: rollingAverage,
      //     type: 'line',
      // }
    ],
    category,
  }
}

const timeSeriesChart = {
  series: [],
  options: {
    chart: {
      fontFamily: "Poppins",
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },

      animations: {
        enabled: false,
      },
    },
    markers: {
      size: 4, // Size of the marker. You can adjust as per your requirement.
      strokeWidth: 2, // Width of the marker border
      hover: {
        size: 5, // Size of the marker while hovering over it.
      },
    },
    colors: ["#663FF1", "#43D37D", "#E70C89", "#FFC839", "#FFA300", "#FF0000", "#0CAFA0", "#0033FF"],
    stroke: {
      width: 4, // Adjust the stroke width
      // curve: 'smooth',
      lineCap: "round", // Make the end of the line rounded
    },
    // title: {
    //     text: 'Comparative attention',
    //     align: 'left'
    // },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#ffffff"], // takes an array which will be repeated on columns
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (value) => {
          // return value
          // Convert the value (which is a date string) to a Date object
          // Format the date using date-fns
          return formatDate(value) // or any other format you prefer
        },
        rotate: -90,
      },

      title: {
        text: "date",
      },
    },
    yaxis: {
      title: {
        text: "Total Brand Engagement",
      },
      // min: 5,
      // max: 40
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
      // floating: true,
    },
  },
}

const NPSstream = (allData) => {
  const series = [
    {
      name: "negative",
      type: "column",
      data: [],
      // stackedGroup: 'group1'
    },

    {
      name: "neutral",
      type: "column",
      data: [],
      // stackedGroup: 'group1'
    },
    {
      name: "positive",
      type: "column",
      data: [],
      // stackedGroup: 'group1'
    },
  ]
  const _data = allData
  const modifiedOriginalArray = []
  if (!allData || !allData?.length) return [series, []]

  for (let _d of _data) {
    // if (
    //   (!_d?.total_neutral_nps && _d?.total_neutral_nps !== 0) ||
    //   (!_d?.total_positive_nps && _d?.total_positive_nps !== 0) ||
    //   (!_d?.total_negative_nps && _d?.total_negative_nps !== 0)
    // ) {
    //   console.log("nps graph || skipping for undefined or null  value")
    //   continue
    // }
    const _total = (_d?.total_positive_nps ?? 0) + (_d?.total_negative_nps ?? 0) + (_d?.total_neutral_nps ?? 0)
    if (!_total) {
      console.log("nps graph || skipping for zero  value")
      continue
    }
    series[0].data.push({
      x: _d.date,
      y: ((_d?.total_negative_nps / _total) * 100).toFixed(2),
    })
    series[1].data.push({
      x: _d.date,
      y: ((_d?.total_neutral_nps / _total) * 100).toFixed(2),
    })

    series[2].data.push({
      x: _d.date,
      y: ((_d?.total_positive_nps / _total) * 100).toFixed(2),
    })
    modifiedOriginalArray.push(_d)
  }
  return [series, modifiedOriginalArray]
}

let multiGraph = {
  series: [
    {
      name: "negative",
      type: "column",
      data: [4, 34, 2, 22, 2, 4, 34, 2, 22, 2],
      // stackedGroup: 'group1'
    },

    {
      name: "neutral",
      type: "column",
      data: [12, 0, 10, 8, 8, 12, 0, 10, 8, 8],
      // stackedGroup: 'group1'
    },
    {
      name: "positive",
      type: "column",
      data: [84, 66, 90, 70, 90, 84, 66, 90, 70, 90],
      // stackedGroup: 'group1'
    },
    // {
    //     name: 'NPS',
    //     type: 'line',
    //     data: [-2, 23, 3, 32, 23,-2, 23, 3, 32, 23],
    // }
  ],
  options: {
    dataLabels: {
      // enabled: false  // This will hide the data labels
      enabled: false,
    },
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [0, 0, 0, 0],
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    colors: ["#DD5C5C", "#FF9D00", "#43D37D", "#000000"],
    xaxis: {
      // type:"datetime"
    },
    yaxis: [
      {
        max: 100,
        min: 0,
        tickAmount: 4,
        title: {
          text: "Response %",
        },
      },
      {
        max: 100,
        show: false,
        min: 0,
        title: {
          text: "Response %",
        },
      },
      {
        max: 100,
        min: 0,
        show: false,
        title: {
          text: "Response %",
        },
      },
      // {
      //     seriesName: 'NPS',
      //     opposite: true,
      //     min: -100,
      //     max: 100,
      //     forceNiceScale: false,
      //     tickAmount: 4,
      //     title: {
      //         text: "NPS"
      //     }
      // }
    ],
    markers: {
      size: 5,
    },
  },
}

const POST_TYPE = [
  {
    title: "All posts",
    value: "ALL",
  },
  {
    title: "Audience posts",
    value: "AUDIENCE",
    filters: {
      is_owned_by_brand: false,
    },
  },
  {
    title: "Owned posts",
    value: "OWNED",
    filters: {
      is_owned_by_brand: true,
    },
  },
  {
    title: "Influencer posts",
    value: "INFLUENCER",
    filters: {
      is_influencer_content: true,
    },
  },
]

const TimeSeriesChart = ({
  title = "",
  className = "",
  yaxisTitle = "",
  time = 30,
  sectionDetails,
  filter,
  fieldName = "total_engagement",
  delay = 500,
  skipFillZero = false,
  onMarkerClick,
  colorConfig = {},
  period = "day",
  minDate = null,
}) => {
  const { payload, loader } = useLocalBoundData({
    ...sectionDetails,
    filter: filter,
  })
  const [showGraph, setShowGraph] = useState(false)
  const series = timeseriesdataparcer(payload, fieldName, undefined, time, skipFillZero, colorConfig, period)
  const _onMarkerClick = (event, chartContext, config) => {
    const data = series[config?.seriesIndex]?.data?.[config?.dataPointIndex]
    // console.log(data)
    if (onMarkerClick) {
      onMarkerClick(data)
    }
  }
  useEffect(() => {
    if (payload && showGraph) return
    const timer = setTimeout(() => {
      if (!payload) {
        return
      }
      setShowGraph(true)
    }, delay) // 2 seconds delay

    return () => clearTimeout(timer) // cleanup timer on component unmount
  }, [payload, filter])

  // console.log(
  //   series, section
  // )

  const xAxisChartConfig = { ...timeSeriesChart.options.xaxis }
  if (minDate !== null) {
    let allDates = []

    // Iterate through each brand
    series?.forEach((brand) => {
      // Iterate through each data point of the brand
      brand?.data?.forEach((dataPoint) => {
        // Push the date string to the allDates array
        allDates?.push(new Date(dataPoint?.x))
      })
    })

    // Find the minimum date
    let minDate = new Date(Math.min(...allDates))
    minDate = dayjs(minDate)?.subtract(1, "day")
    xAxisChartConfig.min = new Date(minDate).getTime()
  }

  const customFollowersTooltip = ({ series: tSeries, seriesIndex, dataPointIndex, w }) => {
    const timestamp = series[seriesIndex].data[dataPointIndex].x
    const date = formatDate(timestamp)
    const data = series
      .map((s, i) => {
        const d = s.data.filter((x) => x.x === timestamp)
        return {
          name: s.name,
          value: d[0]?.y,
          color: s.color,
        }
      })
      .filter((x) => x.value !== undefined)

    let tooltipContent = `<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">${date}</div>`
    data.forEach((d) => {
      tooltipContent += `
        <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 2; display: flex;">
            <span class="apexcharts-tooltip-marker" style="background-color: ${d.color};"></span>
            <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                <div class="apexcharts-tooltip-y-group">
                    <span class="apexcharts-tooltip-text-y-label">${d.name}: </span>
                    <span class="apexcharts-tooltip-text-y-value">${num2Hip(d.value)}</span>
                </div>
            </div>
        </div>`
    })

    return tooltipContent
  }

  return (
    <Card className={`padding-y-5x padding-x-5x full ${className}`}>
      <h3 className=" margin-bottom-4x headline-small-highlight">{title}</h3>
      <WrappedInLoader data={payload} loader={loader || !showGraph}>
        {!payload || !payload?.length || !showGraph ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              ...timeSeriesChart.options,
              chart: { ...timeSeriesChart.options.chart, events: { markerClick: _onMarkerClick } },
              yaxis: { title: { text: yaxisTitle }, labels: { formatter: (x) => num2Hip(x) } },
              xaxis: xAxisChartConfig,
              tooltip: {
                custom: sectionDetails?.section === "timeseries_followers" ? customFollowersTooltip : undefined,
              },
            }}
            series={series}
            type="line"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const BrandMention = ({
  title = "Brand Mention",
  className = "",
  yaxisTitle = "",
  time = "30",
  sectionDetails = GET_CALLS.timeseries_brand_mentions,
  filter,
  fieldName = "total_engagement",
  delay = 200,
  onMarkerClick,
}) => {
  const [_time, setTime] = useState("timeframe=12")
  const { payload, loader } = useLocalBoundData({
    ...sectionDetails,
    skip: !time,
    filter: _time ? { ...Object.fromEntries(new URLSearchParams(_time).entries()) } : {},
  })
  const [showGraph, setShowGraph] = useState(false)

  useEffect(() => {
    setShowGraph(false)
    const timer = setTimeout(() => {
      if (!payload) {
        return
      }
      setShowGraph(true)
    }, delay) // 2 seconds delay

    return () => clearTimeout(timer) // cleanup timer on component unmount
  }, [payload, filter])

  const onBarClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || config.seriesIndex < 0) return
    const _data = {}
    _data.originalValue = payload[config?.dataPointIndex]

    if (_time === "timeframe=84&period=week") {
      if (_data?.originalValue?.week !== undefined || _data?.originalValue?.week !== null) {
        _data.start_date = getStartDateOfWeekBasedOnCurrentWeek(_data?.originalValue?.week)
        _data.days_to_add = 7
      }
    } else if (_time === "timeframe=365&period=month") {
      const { startDate, has31Days } = getMonthStartAndEndDateBasedOnCurrentMonth(
        _data?.originalValue?.month,
        _data?.originalValue?.year,
      )
      _data.start_date = startDate
      _data.days_to_add = has31Days ? 31 : 30
    } else {
      _data.start_date = _data?.originalValue?.date
    }

    _data.filters = {
      mention_type: config.seriesIndex === 1 ? 2 : 1,
    }

    onMarkerClick && onMarkerClick(_data)
  }
  const _value = mentionParser(payload, _time)
  return (
    <Card className={`padding-y-5x padding-x-5x full ${className}`}>
      <div className="flex j-space-between">
        <h3 className=" margin-bottom-4x headline-small-highlight">{title}</h3>

        <Select
          type="content"
          styleType="capsule"
          onChange={(e) => setTime(e.target.value)}
          value={_time}
          className="sort-filter margin-x-2x"
        >
          {[
            { value: "timeframe=12", title: "By days" },
            { value: "timeframe=84&period=week", title: "By weeks" },
            { value: "timeframe=365&period=month", title: "By months" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select>
      </div>
      <WrappedInLoader data={payload} loader={loader || !showGraph}>
        {!payload || !payload?.length || !showGraph ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              yaxis: {
                title: {
                  text: "Total mentions",
                },
              },
              chart: {
                type: "bar",
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
                events: {
                  click: onBarClick,
                },
              },
              xaxis: {
                categories: _value?.category,
              },
            }}
            series={_value.series}
            type="bar"
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const BrandEngagement = ({
  title = "Brand Engagement",
  className = "",
  yaxisTitle = "",
  time = "30",
  sectionDetails = GET_CALLS.timeseries_brand_engagement,
  filter,
  fieldName = "total_engagement",
  delay = 200,
  onMarkerClick,
}) => {
  const [_time, setTime] = useState("timeframe=365&period=month")
  const { payload, loader } = useLocalBoundData({
    ...sectionDetails,
    skip: !time,
    filter: _time ? { ...Object.fromEntries(new URLSearchParams(_time).entries()) } : {},
  })

  const [showGraph, setShowGraph] = useState(false)
  const [series, setSeries] = useState([])
  const [categories, setCategories] = useState([])
  const [activeLegends, setActiveLegends] = useState([0, 1, 2])

  useEffect(() => {
    setShowGraph(false)
    const timer = setTimeout(() => {
      if (!payload) {
        return
      }
      setShowGraph(true)
      setActiveLegends([0, 1, 2])
    }, delay) // 2 seconds delay

    return () => clearTimeout(timer) // cleanup timer on component unmount
  }, [payload, filter])

  const onBarClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0 || !payload[config?.dataPointIndex]) return
    const _data = {}
    _data.originalValue = {
      ...payload[config?.dataPointIndex],
      brand_title: payload[config?.dataPointIndex]?.brand_name ?? "Brand Engagement",
    }

    if (_time === "timeframe=84&period=week") {
      if (_data?.originalValue?.week !== undefined || _data?.originalValue?.week !== null) {
        _data.start_date = getStartDateOfWeekBasedOnCurrentWeek(_data?.originalValue?.week)
        _data.days_to_add = 7
      }
    } else if (_time === "timeframe=365&period=month") {
      const { startDate, has31Days } = getMonthStartAndEndDateBasedOnCurrentMonth(
        _data?.originalValue?.month,
        _data?.originalValue?.year,
      )
      _data.start_date = startDate
      _data.days_to_add = has31Days ? 31 : 30
    } else {
      _data.start_date = _data?.originalValue?.date
    }

    // _data.filters={
    //     mention_type : config.seriesIndex === 1 ? 2 :1
    // }
    // console.log(_data)
    onMarkerClick && onMarkerClick(_data)
  }

  const onLegendClick = (chartContext, seriesIndex, config) => {
    setActiveLegends(
      activeLegends.includes(seriesIndex)
        ? activeLegends.filter((x) => x !== seriesIndex)
        : [...activeLegends, seriesIndex],
    )
  }

  useEffect(() => {
    const _value = engagementParser(payload, _time, activeLegends)
    setSeries(_value?.series)
    setCategories(_value?.category)
  }, [payload, activeLegends])
  return (
    <Card className={`padding-y-5x padding-x-5x full ${className}`}>
      <div className="flex j-space-between">
        <h3 className=" margin-bottom-4x headline-small-highlight">{title}</h3>

        <Select
          type="content"
          styleType="capsule"
          onChange={(e) => setTime(e.target.value)}
          value={_time}
          className="sort-filter margin-x-2x"
        >
          {[
            { value: "timeframe=12", title: "By days" },
            { value: "timeframe=84&period=week", title: "By weeks" },
            { value: "timeframe=365&period=month", title: "By months" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select>
      </div>
      <WrappedInLoader data={payload} loader={loader || !showGraph}>
        {!payload || !payload?.length || !showGraph ? (
          <NotEnoughData />
        ) : (
          <Chart
            options={{
              chart: {
                type: "line",
                stacked: true,
                toolbar: {
                  show: false,
                },
                animations: {
                  enabled: false,
                },
                zoom: {
                  enabled: false,
                },
                events: {
                  click: onBarClick,
                  legendClick: onLegendClick,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "60%",
                },
              },
              xaxis: {
                categories: categories,
              },
              colors: ["#663FF1", "#9273FF", "#C5B4FF", "#663FF1"],
              stroke: {
                width: [0, 0, 0, 3],
                // colors: ['#fff', '#fff', '#fff', "#663FF1"]
              },
              yaxis: {
                title: {
                  text: "Engagement",
                  offsetX: 2,
                },
                labels: {
                  formatter: (x) => num2Hip(x),
                },
              },
              dataLabels: {
                enabled: false,
              },
              legend: {
                formatter: (val, _) => (val === "Rolling Average" ? "" : val),
                markers: {
                  width: [12, 12, 12, 0],
                },
              },
            }}
            series={series}
            height={350}
          />
        )}
      </WrappedInLoader>
    </Card>
  )
}

const BrandStats = (props) => {
  const [fromDate, setFromDate] = useState(getSubtractedDate(new Date(), 29, "YYYY-MM-DD"))
  const [toDate, setToDate] = useState(formatDate(new Date(), "YYYY-MM-DD"))
  // const [fromDate, setFromDate] = useState('2023-09-01')
  // const [toDate, setToDate] = useState('2023-11-30')

  const [data, dispatch] = useContext(dataContext)
  let competitors = data?.me?.brand?.sub_brands?.filter((x) => x?.sub_brand_type === 1).map((x) => x?.id) ?? []
  competitors?.unshift(data?.me?.brand?.id)

  const { payload: kpm_payload, loader: kpm_loader } = useLocalBoundData({
    ...GET_CALLS.key_performance_metrics,
    filter: {
      brand_id_list: competitors?.join(","),
      // timeframe: "30",
      post_date__gte: fromDate,
      post_date__lte: toDate,
    },
  })
  const api = useContext(apiContext)
  const [time, setTime] = useState("30")
  const { payload: nps_payload, loader: nps_loader } = useLocalBoundData({
    ...GET_CALLS.timeseries_nps,
    filter: { post_date__gte: fromDate, post_date__lte: toDate },
  })
  const [type, setType] = useState("ALL")
  const toast = useToast()
  const [brandColorConfig, setBrandColorConfig] = useState({})

  const openContentPopup = (data) => {
    if (!data?.originalValue) {
      toast("No data on that day")
      return
    }
    // api?.alert?.dataPointContentPopup(data)

    dispatch({
      date_specific_dialog_data: data,
    })
  }

  const [nps_stream, modifiedNPSArray] = NPSstream(nps_payload)

  const onNpsDateClick = (event, chartContext, config) => {
    if (config?.dataPointIndex < 0) return
    const nps_score_map = {
      0: "-1",
      1: "0",
      2: "1",
    }
    openContentPopup({
      originalValue: modifiedNPSArray[config?.dataPointIndex],
      nps_score: nps_score_map[config?.seriesIndex],
      excludeFilters: {
        exc_comment_mentioned: true,
      },
    })
  }
  useEffect(() => {
    if (!data?.me?.brand) {
      return
    }
    setBrandColorConfig(genarateBrandColor(data?.me?.brand))
  }, [data?.me?.brand])

  if (data?.me?.brand?.settings?.is_political_engage_enabled) {
    // navigate to comments dashboard page
    return <Navigate to="/brand-dashboard/comments" />
  }

  return (
    <div className="padding-top-4x">
      <div className="flex j-space-between a-center margin-top-4x margin-bottom-4x">
        <h2 className="headline-large">Content Statistics</h2>
        <DateRangePicker
          fromDate={fromDate}
          toDate={toDate}
          onChange={(from, to) => {
            setFromDate(from)
            setToDate(to)
          }}
        />
      </div>
      <NPSGraphs
        timeFrame={30}
        showNpsGraphs={false}
        showPrioritizedGraphs={false}
        hideIfNoData
        fromDate={fromDate}
        toDate={toDate}
      />
      <WrappedInLoader data={kpm_payload} loader={kpm_loader}>
        <h2 className="headline-medium-highlight margin-top-4x margin-bottom-4x">Key performance metrics</h2>
        <div className="flex margin-bottom-4x">
          <BarChart
            title="Brand attention"
            subtitle="(All engagement)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_brand_engagement")}
          />
          <BarChart
            title="Brand attention"
            subtitle="(likes)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_likes")}
          />
          <BarChart
            title="Brand attention"
            subtitle="(comments)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_comments")}
          />
          <BarChart
            title="Brand attention"
            subtitle="(shares)"
            className={`full`}
            data={KVMDataParcer(kpm_payload, "total_shares")}
          />
        </div>
        <div className="flex margin-bottom-8x">
          <BarChart
            title="Follower count"
            subtitle="(today)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_followers")}
          />
          <BarChart
            title="# of people posting"
            subtitle="(total)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_people_posting")}
          />
          <BarChart
            title="# of posts"
            subtitle="(total)"
            className={`margin-right-2x full`}
            data={KVMDataParcer(kpm_payload, "total_posts")}
          />
          <DonutChart title="NPS" data={KVMDataParcer(kpm_payload, "nps")} />
        </div>
      </WrappedInLoader>
      <BrandMention onMarkerClick={openContentPopup} className="margin-y-4x" />
      <BrandEngagement onMarkerClick={openContentPopup} className="margin-y-4x" />
      <div className="flex j-space-between a-center">
        <h2 className="headline-medium-highlight margin-top-8x margin-bottom-4x">Time series - key results</h2>

        {/* <Select
          type="content"
          styleType="capsule"
          onChange={(e) => setTime(e.target.value)}
          value={time}
          className="sort-filter margin-x-2x"
        >
          {[
            { value: "30", title: "30 days" },
            { value: "60", title: "60 days" },
          ].map((_srt) => (
            <option key={_srt?.value} value={_srt?.value}>
              {_srt?.title}
            </option>
          ))}
        </Select> */}
      </div>

      <TimeSeriesChart
        colorConfig={brandColorConfig}
        skipFillZero
        onMarkerClick={(d) => openContentPopup({ ...d, filters: POST_TYPE.find((x) => x.value === type)?.filters })}
        delay={210}
        sectionDetails={GET_CALLS.timeseries_brand_total_engagement}
        time={Number(time)}
        filter={{ post_date__gte: fromDate, post_date__lte: toDate, post_type: type }}
        className="margin-bottom-4x"
        title={
          <div className="flex j-space-between">
            <span>Comparative attention</span>
            <div className="flex">
              {POST_TYPE.map((x, i) => (
                <div
                  key={i}
                  className={`${2} margin-x-2x cursor-pointer ${type === x.value ? "nc75-fg" : "nc30-fg"}`}
                  onClick={() => setType(x.value)}
                >
                  {x.title}{" "}
                </div>
              ))}
            </div>
          </div>
        }
        yaxisTitle="Total Brand Engagement"
      />
      <div className="flex margin-bottom-4x">
        <TimeSeriesChart
          colorConfig={brandColorConfig}
          delay={210}
          className={`padding-y-5x margin-right-2x`}
          time={Number(time)}
          sectionDetails={GET_CALLS.timeseries_followers}
          fieldName="followers"
          skipFillZero
          filter={{ timeframe: "182", period: "week" }}
          title={`Follower count`}
          minDate={true}
          yaxisTitle="# of followers"
          period="week"
        />
        <TimeSeriesChart
          skipFillZero
          colorConfig={brandColorConfig}
          delay={210}
          onMarkerClick={openContentPopup}
          className={`padding-y-5x margin-left-2x`}
          time={Number(time)}
          sectionDetails={GET_CALLS.total_people_posted}
          fieldName="total_people_posted"
          filter={{ post_date__gte: fromDate, post_date__lte: toDate }}
          title={`Number of people posting`}
          yaxisTitle="# of people posting"
        />
      </div>
      <div className="flex margin-bottom-2x">
        <Card className={`full padding-y-5x `}>
          <h3 className=" margin-bottom-4x padding-x-5x">{"NPS"}</h3>
          <WrappedInLoader data={nps_payload} loader={nps_loader}>
            <Chart
              options={{
                ...multiGraph.options,
                chart: { ...multiGraph.options.chart, events: { click: onNpsDateClick } },
              }}
              series={nps_stream}
              type="bar"
              height={350}
            />
          </WrappedInLoader>
        </Card>
      </div>
    </div>
  )
}

export { BrandStats, KVMDataParcer }
