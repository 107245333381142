import * as Dialog from "@radix-ui/react-dialog";
import { ThinTimesLargeMono } from "./../Icons";
import { Button } from "../button";




const Modal = ({ open, onOpenChange, children }) => {
    return (
        <Dialog.Root open={open} onOpenChange={onOpenChange}>
            {children}
        </Dialog.Root>
    );
}

function ModalContent({ title, children, contentBodyWidthClassName = "dialog-max-width-400", overlayClassName = "", titleContainerClassName = "flex a-center j-space-between padding-x-5x padding-y-3x border-bottom-1 nc10-border" }) {
    return (
        <Dialog.Portal>
            <Dialog.Overlay className={`dialog-overlay ${overlayClassName}`} />
            <Dialog.Content className={`dialog-content ${contentBodyWidthClassName}`}>
                <div className={titleContainerClassName}>
                    <Dialog.Title className="name-only-dialog-title headline-medium-highlight" >{title}</Dialog.Title>
                    <Dialog.Close asChild className="dialog-close-button cursor-pointer">
                        <span className="p-1 flex">
                            {ThinTimesLargeMono}
                        </span>
                    </Dialog.Close>
                </div>
                {children}
            </Dialog.Content>
        </Dialog.Portal>
    );
}

Modal.Button = Dialog.Trigger;
Modal.Close = Dialog.Close;
Modal.Content = ModalContent;

export {
    Modal
}
