import { useContext } from "react"
import { dataContext } from "../contexts/datacontext"
import { PIN_CLIENT_ID, TT_CLIENT_KEY, YT_CLIENT_ID, PP_CLIENT_ID, IG_CLIENT_ID, FB_GRAPH_APP_ID, ENV } from "../consts"
import { apiContext } from "../contexts/apicontext"

function generateCSRFState() {
  return Math.random().toString(36).substring(2)
}
function generateRelativeURL(path) {
  return `${window.location.origin}${path}`
}
function generatePinterestURL(client_id, Redirect_uri = window.location.href) {
  return `https://www.pinterest.com/oauth/?client_id=${client_id}&redirect_uri=${Redirect_uri}&response_type=code&scope=boards:read,pins:read,user_accounts:read&state=${generateCSRFState()}`
}
function generateTiktokURL(client_key, Redirect_uri = window.location.href) {
  return `https://www.tiktok.com/v2/auth/authorize/?client_key=${client_key}&response_type=code&scope=user.info.basic,user.info.profile,user.info.stats,video.list&redirect_uri=${Redirect_uri}&state=${generateCSRFState()}`
}
function generateYTURL(client_id, Redirect_uri = window.location.href) {
  const params = {
    client_id: client_id,
    redirect_uri: Redirect_uri,
    response_type: "code",
    access_type: "offline",
    scope: [
      "openid",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/youtube",
      "https://www.googleapis.com/auth/youtube.force-ssl",
      "https://www.googleapis.com/auth/youtube.readonly",
      "https://www.googleapis.com/auth/youtube.upload",
    ].join(" "),
    include_granted_scopes: false,
    state: generateCSRFState(),
  }
  console.log(params)
  const _url = "https://accounts.google.com/o/oauth2/v2/auth?" + new URLSearchParams(params).toString()
  console.log(_url)
  return _url

  const url = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${client_id}&redirect_uri=${Redirect_uri}&scope=openid+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/youtube+https://www.googleapis.com/auth/youtube.force-ssl+https://www.googleapis.com/auth/youtube.readonly+https://www.googleapis.com/auth/youtube.upload&access_type=offline&include_granted_scopes=false&state=${generateCSRFState()}`
  // console.log(url)
  return _url
}
function generatePPURL(client_id, Redirect_uri = window.location.href) {
  const sandboxPrefix = ENV === "development" ? "sandbox." : ""
  return `https://www.${sandboxPrefix}paypal.com/connect?flowEntry=static&client_id=${client_id}&scope=openid email https://uri.paypal.com/services/paypalattributes&redirect_uri=${Redirect_uri}`
}
function generateIGURL(client_id, Redirect_uri = window.location.href) {
  return `https://api.instagram.com/oauth/authorize?client_id=${client_id}&redirect_uri=${Redirect_uri}&scope=user_profile,user_media&response_type=code`
}
function generateFBURL(client_id, Redirect_uri = window.location.href, reconnect = false, isTestUser = false) {
  let scopes = [
    "instagram_basic",
    "pages_show_list",
    "public_profile",
    "instagram_manage_insights",
    "instagram_manage_comments",
    "pages_read_engagement",
    "ads_management",
    "business_management",
  ]

  return `https://www.facebook.com/v14.0/dialog/oauth?client_id=${client_id}&redirect_uri=${Redirect_uri}&scope=${scopes.join(",")}&response_type=token,granted_scopes${reconnect ? "&auth_type=rerequest" : ""}&state=${generateCSRFState()}`
}

function useSocial() {
  const [data] = useContext(dataContext)
  const api = useContext(apiContext)
  const connect = (platform, reconnect = false) => {
    console.info(`connecting: ${platform}`)
    switch (platform) {
      case "instagram":
        api?.alert?.alertBeforeIGConnect(() => {
          window.location.href = generateIGURL(IG_CLIENT_ID, generateRelativeURL("/oauth-callback-instagram"))
        })
        break
      case "ig_graph":
        window.location.href = generateFBURL(
          FB_GRAPH_APP_ID,
          generateRelativeURL("/oauth-callback-facebook"),
          reconnect,
          data.authUser?.is_test_user,
        )
        break
      case "pinterest":
        window.location.href = generatePinterestURL(PIN_CLIENT_ID, generateRelativeURL("/oauth-callback-pinterest"))
        break
      case "tiktok":
        api?.alert?.alertBeforeTTConnect(() => {
          window.location.href = generateTiktokURL(TT_CLIENT_KEY, generateRelativeURL("/oauth-callback-tiktok"))
        })
        break
      case "youtube":
        window.location.href = generateYTURL(YT_CLIENT_ID, generateRelativeURL("/oauth-callback-youtube"))
        break
      case "paypal":
        window.location.href = generatePPURL(PP_CLIENT_ID, generateRelativeURL("/oauth-callback-paypal"))
        break
      default:
        console.error("Platform unsupportred")
    }
  }
  const reconnect = (platform) => {
    switch (platform) {
      case "instagram":
        if (data?.me?.connected_accounts?.instagram?.facebook_connected_instagram_page) return connect("ig_graph", true)
        else return connect("instagram", true)
      default:
        return connect(platform, true)
    }
  }
  // temporary debug code
  window.connectFacebook = () => {
    connect("ig_graph")
  }
  // Todo: Create disconnect
  return {
    connect,
    reconnect,
  }
}

export { useSocial }
